import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import add_icon from "../../Assests/Images/icon_add.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AddCareGiver from "../../Components/CareGiver/AddCareGiver";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import style from "./CareGiver.module.css";
import IndependicareApi from "../../Helpers/Api";
import Toster from "../../Toster/Toster";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../LoaderComponent/LoaderComponent";
import AssignSenior from "./AssignSenior";
import useDebounce from "../../Helpers/Debounce";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import deleteImage from "../../Assests/Images/delete_image.png";
import debounce from "debounce";
import { LIST_TYPE } from "../../Utils/Constant";

function CareGiver() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [careGiverDeleteId, setCareGiverDeleteId] = useState();

  const handleCloseModal = () => {
    setCareGiverSearch("");
    setCurrentCareGiverPage(1);
    setShowModal(false);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleShowModal = () => setShowModal(true);
  const [showAssignCareGiverModal, setShowAssignCareGiverModal] =
    useState(false);
  const handleCloseModalAssignCareGiver = () =>
    setShowAssignCareGiverModal(false);
  const handleShowModalAssignCareGiver = () =>
    setShowAssignCareGiverModal(true);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchPerson, setSearchPerson] = useState("");

  const [careGiverSearch, setCareGiverSearch] = useState("");

  const [addedCaregiverList, setAddedCaregiverList] = useState([]);
  const [careGiverList, setCareGiverList] = useState([]);

  const [personList, setPersonList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [levelList, setLevelList] = useState([]);

  const [addedCaregiverId, setAddedCaregiverId] = useState("");
  const [selectRelation, setSelectRelation] = useState("");
  const [selectPerson, setSelectPerson] = useState("");
  const [selectLevel, setSelectLevel] = useState("");
  const [selectPermission, setSelectPermission] = useState([]);
  const [dutyHoursData, setDutyHoursData] = useState([]);
  const [isPersonError, setIsPersonError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [pageNo, setPageNo] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [addedCaregiverListCount, setAddedCaregiverListCount] = useState(0);

  const [currentCareGiverPage, setCurrentCareGiverPage] = useState(1);
  const [CaregiverListCount, setCaregiverListCount] = useState(0);

  const [personOption, setPersonOption] = useState([]);
  const [showPerson, setShowPerson] = useState(false);
  const [selectedPersonName, setSelectedPersonName] = useState(""); // Correct function definition
  const [selectedPersonId, setSelectedPersonId] = useState(null); // Correct function definition
  const [currentPageForPerson, setCurrentPageForPerson] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const listInnerRef = useRef();

  const handleClosePerson = () => {
    setShowPerson(false);
  };

  const handleShowPerson = () => {
    setShowPerson(true);
    getPersonList(1, "");
  };

  const debouncedSearchPerson = useDebounce((term) => {
    getPersonList(1, term);
  }, 500);

  const getPersonList = (currentPageForPerson, searchPerson) => {
    try {
      setIsLoading(true);
      IndependicareApi.getPersonListAssignPerson(
        token,
        currentPageForPerson,
        addedCaregiverId,
        searchPerson,
        LIST_TYPE.ACTIVE
      ).then((res) => {
        setIsLoading(false);
        if (res?.code === STATUS_CODE.SUCCESS) {
          const newPersons = res?.data?.person_list;
          if (currentPageForPerson === 1) {
            setPersonOption(newPersons);
          } else {
            setPersonOption((prevPersons) =>
              Array.isArray(prevPersons)
                ? [...prevPersons, ...newPersons]
                : newPersons
            );
          }
          setCurrentPageForPerson(currentPageForPerson);
          setIsLoadMore(res?.data?.loadMore);
        } else if (
          res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          res?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(res?.data?.message, "error");
        } else {
          Toster(t(STATUS_MSG[res?.data?.code]), "error");
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.error(t("ERROR_FETCHING_PERSON_LIST"), error);
    }
  };

  const handleScroll = () => {
    if (!isLoadMore) return;
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        onPageChangePerson(currentPageForPerson + 1);
      }
    }
  };

  const onPageChangePerson = (pageNumber) => {
    getPersonList(pageNumber, searchPerson);
  };

  const handleSubmitPerson = () => {
    // if (tempPersonId == "") {
    //   Toster(t("PLEASE_SELECT_PERSON"), "error");
    //   return;
    // }
    // setSelectedPersonId(tempPersonId);
    // setSelectedPerson(tempPersonName);
    // handleClosePerson();
  };

  // person model form end...

  const pageLimit = 10;

  // const handleScrollCaregiver = () => {
  //   if (!isLoadMore) return;
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight >= scrollHeight - 5) {
  //       onPageChangeAddedCareGiver(currentCareGiverPage + 1);
  //     }
  //   }
  // };

  const handleScrollCaregiver = () => {
    if (!isLoadMore) return;
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        // onPageChangeAddedCareGiver(currentCareGiverPage + 1);
        let roundedNumber = Math.ceil(CaregiverListCount / pageLimit);
        if (currentCareGiverPage >= roundedNumber) {
          setIsLoadMore(false);
        } else {
          onPageChangeAddedCareGiver(currentCareGiverPage + 1);
        }
      }
    }
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAddedCareGiverList(pageNumber, searchQuery);
  };
  const onPageChangeAddedCareGiver = (pageNumber) => {
    setCurrentCareGiverPage(pageNumber);
    getCareGiverList(pageNumber, careGiverSearch);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAddedCareGiverList(1, "");
    getCareGiverList(1, "");
    //meta apis
    getPersonList();
    getRelationList();
    getPermissionList();
    getLevelList();
  }, []);

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const onSelectCareGiver = (index) => {
    setCareGiverList((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };
  const getSelectedIds = () => {
    return careGiverList
      .filter((item) => item.isSelected)
      .map((item) => item.id.toString());
  };
  const getAddedCareGiverList = async (pageNo, searchTerm) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.getAddedCareGiverList(
        pageNo,
        searchTerm,
        token
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];

      if (res?.code === STATUS_CODE.SUCCESS) {
        const addedCaregiverList = res.data.addedCaregiverList;

        setAddedCaregiverList(addedCaregiverList);
        setAddedCaregiverListCount(res.data.count);
        setIsLoadMore(res?.data?.loadMore);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching list:", error);
    }
  };
  // useEffect(() => {
  //   getAddedCareGiverList(currentCareGiverPage, searchQuery);
  // }, [showModal]);

  const getCareGiverList = async (pageNo, searchTerm) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.getCareGiverList(
        pageNo,
        searchTerm,
        token
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];

      if (res?.code === STATUS_CODE.SUCCESS) {
        setCurrentPage(pageNo);
        setCareGiverSearch(searchTerm);
        // setSearchQuery(searchTerm);
        const caregiverList = res.data.caregiverList;
        setCaregiverListCount(res.data.count);

        if (pageNo === 1) {
          setCareGiverList(caregiverList);
        } else {
          setCareGiverList((preCaregiverList) => [
            ...preCaregiverList,
            ...caregiverList,
          ]);
        }
        // setCareGiverList(caregiverList);
        setIsLoadMore(res?.data?.loadMore);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching list:", error);
    }
  };

  useEffect(() => {
    getCareGiverList(1, careGiverSearch);
  }, [showModal]);

  const addCareGiverList = async (selectedIDs) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.addCareGiverList(selectedIDs, token);
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        Toster(t("CAREGIVER_ADDED_SUCCESSFULLY"), "success");
        handleCloseModal();
        setCareGiverSearch("");
        getAddedCareGiverList(1, searchQuery);
        getCareGiverList(1, careGiverSearch);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };
  const removeCareGiverList = async (caregiverId) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.removeCareGiverList(
        caregiverId,
        token
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];

      if (res?.code === STATUS_CODE.SUCCESS) {
        Toster(t("CAREGIVER_DELETED_SUCCESSFULLY"), "success");
        getAddedCareGiverList(1, searchQuery);
        getCareGiverList(1, careGiverSearch);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };

  const getRelationList = async () => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.fetchRelation(token, LIST_TYPE.ACTIVE);
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const transformedRelationListArray = res.data.relation.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setRelationList(transformedRelationListArray);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };
  const getPermissionList = async () => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.fetchPermission(
        token,
        LIST_TYPE.ACTIVE
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const transformedPermissionListArray = res.data.permission_list.map(
          (item) => ({
            id: item.id,
            name: item.module_name,
          })
        );
        setPermissionList(transformedPermissionListArray);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };
  const getLevelList = async () => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.fetchLevel(token, LIST_TYPE.ACTIVE);
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const transformedLevelListArray = res.data.level.map((item) => ({
          id: item.id,
          name: item.level,
        }));
        setLevelList(transformedLevelListArray);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };
  const assignSeniorApi = async (formData) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.assignSenior(formData, token);
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        handleCloseModalAssignCareGiver();
        Toster(t("ASSIGN_SENIOR_SUCCESSFULLY"), "success");
        getAddedCareGiverList(1, searchQuery);
        getCareGiverList(1, careGiverSearch);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };
  const validateObject = (obj) => {
    // Define the required keys
    const requiredKeys = [
      "added_Caregiver_id",
      "person_id",
      "relation_id",
      "permission_id",
      "level_id",
      "schedule_data",
    ];

    // Check for missing or falsy values for required keys
    for (const key of requiredKeys) {
      if (!obj.hasOwnProperty(key) || !obj[key]) {
        let messageKey;

        // Determine the appropriate message key based on the missing key
        switch (key) {
          case "person_id":
            messageKey = "PLEASE_SELECT_PERSON";
            break;
          case "relation_id":
            messageKey = "PLEASE_SELECT_RELATION";
            break;
          case "permission_id":
            messageKey = "PLEASE_SELECT_PERMISSION";
            break;
          case "level_id":
            messageKey = "PLEASE_SELECT_LEVEL";
            break;
          case "schedule_data":
            messageKey = "PLEASE_SELECT_DUTY_HOURS";
            break;
          default:
            messageKey = "HEADER_IS_MISSING";
            break;
        }

        Toster(t(messageKey), "error");
        return false;
      }
    }

    // Check if schedule_data has a length greater than 0
    if (!Array.isArray(obj.schedule_data) || obj.schedule_data.length === 0) {
      Toster(t("PLEASE_SELECT_DUTY_HOURS"), "error");
      return false;
    }

    for (let i = 0; i < dutyHoursData?.length; i++) {
      if (
        dutyHoursData[i]?.time_slots[0]?.end_time <=
        dutyHoursData[i]?.time_slots[0]?.start_time
      ) {
        Toster(t("END_TIME_SHOULD_BE_GREATER_THAN_START_TIME"), "error");
        return false;
      }
    }

    // If all checks passed
    return true;
  };
  const clearAssignPersonData = () => {
    setSelectRelation("");
    setSelectPerson("");
    setSelectedPersonId("");
    setSelectedPersonName("");
    setSelectLevel("");
    setSelectPermission([]);
    setDutyHoursData([]);
  };

  const debouncedSearch = useDebounce((term) => {
    getAddedCareGiverList(1, term);
    if (term == "") {
      setCurrentPage(1);
    }
  }, 500);

  const debouncedSearchForCaregiver = useDebounce((term) => {
    getCareGiverList(1, term);
  }, 500);

  return (
    <div className="d-flex flex-column justify-content-between w-100">
      <div>
        {isLoading && <Loader />}
        <div>
          <Container>
            <Row className="align-items-center">
              <Col
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
                className="setSearchInputField"
              >
                <CustomSearchField
                  value={searchQuery}
                  setSearchQuery={(val) => {
                    setSearchQuery(val);
                    debouncedSearch(val);
                  }}
                />
              </Col>
              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={handleShowModal}
                  >
                    {t("ADD_CAREGIVER")}
                  </Button>
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header className="d-flex justify-content-between">
                      <Modal.Title className="setModelTitleProperty">
                        {t("ADD_CAREGIVER")}
                      </Modal.Title>
                      <CancelIcon
                        className="closeIconProperty"
                        onClick={handleCloseModal}
                      />
                    </Modal.Header>
                    <Modal.Body>
                      <AddCareGiver
                        careGiverList={careGiverList}
                        onSelectCareGiver={onSelectCareGiver}
                        setCareGiverSearch={setCareGiverSearch}
                        searchValue={careGiverSearch}
                        debouncedSearchForCaregiver={
                          debouncedSearchForCaregiver
                        }
                        listInnerRef={listInnerRef}
                        onScroll={debounce(handleScrollCaregiver, 100)}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <div style={{ flex: 1 }}>
                        {/* <div
                          style={{
                            paddingBottom: CaregiverListCount <= 10 ? 0 : 15,
                          }}
                        >
                          <Pagination
                            count={CaregiverListCount}
                            pageLimit={pageLimit}
                            currentPage={currentCareGiverPage}
                            onPageChange={onPageChangeAddedCareGiver}
                          />
                        </div> */}
                        <Button
                          className="AddSiteSubmitButton"
                          onClick={() => {
                            if (getSelectedIds().length > 0) {
                              addCareGiverList(getSelectedIds().join(", "));
                            } else {
                              Toster(t("PLEASE_SELECT_CAREGIVER"), "error");
                            }
                            setCurrentCareGiverPage(1);
                          }}
                        >
                          {t("ADD_CAREGIVER")}
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={showAssignCareGiverModal}
                    onHide={handleCloseModalAssignCareGiver}
                  >
                    <Modal.Header className="d-flex justify-content-between">
                      <Modal.Title className="setModelTitleProperty">
                        {t("ASSIGN_SENIOR")}
                      </Modal.Title>
                      <CancelIcon
                        className="closeIconProperty"
                        onClick={handleCloseModalAssignCareGiver}
                      />
                    </Modal.Header>
                    <Modal.Body>
                      <AssignSenior
                        personOption={personOption}
                        listInnerRef={listInnerRef}
                        searchQuery={searchPerson}
                        setSearchQuery={setSearchPerson}
                        debouncedSearchPerson={debouncedSearchPerson}
                        handleScroll={handleScroll}
                        handleShowPerson={handleShowPerson}
                        handleClosePerson={handleClosePerson}
                        selectedPersonName={selectedPersonName}
                        selectedPersonId={selectedPersonId}
                        setSelectedPersonName={setSelectedPersonName}
                        setSelectedPersonId={setSelectedPersonId}
                        showPerson={showPerson}
                        isLoading={isLoading}
                        personList={personList}
                        relationList={relationList}
                        permissionList={permissionList}
                        levelList={levelList}
                        selectRelation={selectRelation}
                        setSelectRelation={setSelectRelation}
                        // selectPerson={selectPerson}
                        // setSelectPerson={setSelectPerson}
                        selectLevel={selectLevel}
                        setSelectLevel={setSelectLevel}
                        selectPermission={selectPermission}
                        setSelectPermission={setSelectPermission}
                        dutyHoursData={dutyHoursData}
                        setDutyHoursData={setDutyHoursData}
                        addedCaregiverId={addedCaregiverId}
                        addedCaregiverList={addedCaregiverList}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="AddSiteSubmitButton"
                        onClick={() => {
                          const selectPermissionValues = selectPermission
                            ? selectPermission.map((option) => option.value)
                            : [];
                          const formData = {
                            added_Caregiver_id: addedCaregiverId,
                            person_id: selectedPersonId,
                            relation_id: selectRelation,
                            permission_id: selectPermissionValues.join(", "),
                            level_id: selectLevel,
                            schedule_data: dutyHoursData,
                          };
                          const isValid = validateObject(formData);
                          if (isValid) {
                            assignSeniorApi(formData);
                          }
                        }}
                      >
                        {t("ASSIGN")}
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                    <Modal.Header className="d-flex justify-content-between">
                      <p className={style.Eventproperty}>{t("Alert")}</p>
                      <CancelIcon
                        className="closeIconProperty"
                        onClick={handleCloseDeleteModal}
                      />
                    </Modal.Header>
                    <Modal.Body>
                      <div className={style.deleteClass}>
                        <Image src={deleteImage} />

                        <p>{t("ARE_YOU_SURE_DELETE_CAREGIVER")}</p>
                      </div>

                      <Button
                        type="submit"
                        className="AddSiteSubmitButton"
                        onClick={() => {
                          removeCareGiverList(careGiverDeleteId);

                          handleCloseDeleteModal();
                        }}
                      >
                        {t("OK")}
                      </Button>
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div className={style?.eventTable}>
              <div className="setTableFromBottom">
                {addedCaregiverList.length > 0 ? (
                  // <Table className="customTable" striped hover responsive>
                  //   <thead className="tableHeadProperty">
                  <Table className={style.customTable} striped>
                    <thead className={style.tableHeadProperty}>
                      <tr>
                        <th>{t("NAME")}</th>
                        <th>{t("EMAIL")}</th>
                        <th>{t("SENIORS")}</th>
                        {/* <th>{t("RELATION")}</th> */}
                        <th
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {t("ACTION")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {addedCaregiverList
                        ? addedCaregiverList.map((item, index) => (
                            <tr key={index}>
                              <td
                                // onClick={() => {
                                //   navigate("/caregiverdetails", {
                                //     state: { id: item.id },
                                //   });
                                // }}
                                style={tdAlignClass}
                                className="tdtext"
                              >
                                {item.name}
                              </td>
                              <td style={tdAlignClass} className="tdtext">
                                {item.email}
                              </td>
                              {item.person_names.length ? (
                                <td style={tdAlignClass}>
                                  {item.person_names[0]}{" "}
                                  {item.person_names.length > 1 &&
                                    `& ${item.person_names.length - 1} more `}
                                  <img
                                    onClick={() => {
                                      setAddedCaregiverId(item.id);
                                      clearAssignPersonData();
                                      handleShowModalAssignCareGiver();
                                    }}
                                    style={{ height: "18px", width: "18px" }}
                                    src={add_icon}
                                    alt="assign more senior"
                                  />
                                </td>
                              ) : (
                                <td
                                  style={tdAlignClass}
                                  className="tdtextred"
                                  onClick={() => {
                                    setAddedCaregiverId(item.id);
                                    clearAssignPersonData();
                                    handleShowModalAssignCareGiver();
                                  }}
                                >
                                  {t("ASSIGN_SENIOR")}
                                </td>
                              )}
                              {/* <td style={tdAlignClass} className="tdtext">
                                                    {item.relation}
                                                </td> */}
                              <td>
                                <Dropdown className="setParentDropdown">
                                  <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                    className="custom-dropdown-toggle p-0 border-0"
                                  >
                                    <MoreHorizIcon />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div
                                      onClick={() => {
                                        setCareGiverDeleteId(item.id);
                                        handleShowDeleteModal();
                                      }}
                                      className="d-flex justify-content-around dropdownTextProperty"
                                    >
                                      <Dropdown.Item>
                                        {t("DELETE")}
                                      </Dropdown.Item>
                                      <DeleteOutlineIcon className="iconColor" />
                                    </div>
                                    {/*  */}
                                    <div
                                      onClick={() => {
                                        navigate("/caregiverdetails", {
                                          state: { id: item.id },
                                        });
                                      }}
                                      className="d-flex justify-content-around dropdownTextProperty"
                                    >
                                      <Dropdown.Item>{t("VIEW")}</Dropdown.Item>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                ) : (
                  <div className={style.noRecord}>
                    <p>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Pagination
        count={addedCaregiverListCount}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
}
export default CareGiver;
