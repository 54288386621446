import React, { useEffect, useState } from "react";
import styles from "../Stakeholder/Stakeholder.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomSearchField from "../CustomComponent/CustomSearchField/CustomSearchField";
import { userData } from "../Mockdata/Mockdata";
import { Table } from "react-bootstrap";
import Actionicon from "../../Assests/Images/ActionIcon.png";
import IndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Toster from "../../Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import useDebounce from "../../Helpers/Debounce";
import { clearUserState } from "../../redux/slices/userSlice";
import Loader from "../LoaderComponent/LoaderComponent";
function Stakeholder() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);

  const [isLoading, setIsLoading] = useState(false);

  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addedTagListCount, setAddedTagListCount] = useState(0);
  const pageLimit = 10;
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const payLoads = {
      page_no: pageNumber,
      search: searchQuery,
    };
    getStakeholderList(payLoads);
  };

  const debouncedSearch = useDebounce((term) => {
    const payLoads = {
      page_no: currentPage,
      search: term,
    };
    getStakeholderList(payLoads);
  }, 500);

  useEffect(() => {
    const payLoads = {
      page_no: currentPage,
      search: "",
    };
    getStakeholderList(payLoads);
  }, []);

  const getStakeholderList = (payLoads) => {
    setIsLoading(true);

    IndependicareApi.getStakeholderList(token, payLoads).then((response) => {
      const responseCode = STATUS_MSG[response?.data?.code];
      setIsLoading(false);
      if (response?.code === STATUS_CODE.SUCCESS) {
        setList(response.data.stakeholderList);
        setAddedTagListCount(response?.data?.count);
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  return (
    <div className="d-flex flex-column justify-content-between h-100 w-100">
      {isLoading && <Loader />}
      <div className={styles.alignment}>
        <div className={styles.tbl}>
          {/* <Outlet /> */}
          <div className={styles.tbl}>
            <CustomSearchField
              value={searchQuery}
              setSearchQuery={(val) => {
                setSearchQuery(val);
                debouncedSearch(val);
              }}
            />
            {list.length > 0 ? (
              <Table striped responsive>
                <thead className={styles.thead}>
                  <tr>
                    <th>{t("NAME")}</th>
                    <th>{t("EMAIL")}</th>
                    <th>{t("CONTACT_NUMBER")}</th>
                    <th>{t("REDEAR_LOCATION")}</th>
                    <th>{t("ACTION")}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {list.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td onClick={() => navigate("/stackholder-detail")}>
                        {item.stakeholder_name}
                      </td>
                      <td>{item.stakeholder_email}</td>
                      <td>{item?.dial_code + " " + item.phone_number}</td>
                      <td>{item.street_address}</td>
                      <td
                        className={styles.textCenter}
                        onClick={() =>
                          navigate("/stackholder-detail", {
                            state: { itemId: item?.stakeholder_id },
                          })
                        }
                      >
                        <img src={Actionicon} alt="Logo" />
                      </td>
                    </tr>
                  );
                })} */}
                  {list.length > 0 ? (
                    list.map((item, index) => {
                      return (
                        <tr key={index}>
                          {/* <td onClick={() => navigate("/stackholder-detail")}>
                            {item.stakeholder_name}
                          </td> */}
                          <td>{item.stakeholder_name}</td>
                          <td>{item.stakeholder_email}</td>
                          <td>{item?.dial_code + " " + item.phone_number}</td>
                          <td>{item.street_address}</td>
                          <td
                            className={styles.textCenter}
                            onClick={() =>
                              navigate("/stackholder-detail", {
                                state: { itemId: item?.stakeholder_id },
                              })
                            }
                          >
                            <img src={Actionicon} alt="Logo" />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className={styles.noRecord}>
                      <p>{t("NO_RECORD_FOUND")}</p>
                    </div>
                  )}
                </tbody>
              </Table>
            ) : (
              <div className={styles.noRecord}>
                <p>{t("NO_RECORD_FOUND")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stakeholder;
