import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import {
  CustomPasswordInputField,
  CustomInputFields,
} from "../CustomComponent/CustomInputFields/CustomInputField";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import IndependicareApi from "../../Helpers/Api";
import Toster from "../../Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Loader from "../LoaderComponent/LoaderComponent";
import { useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import style from "./ResetPassword.module.css";
import { ValidationSchema } from "../../Utils/ValidationSchema";

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const userEmail = location.state.userEmail;

  const { register, handleSubmit, reset, formState, setValue, control } =
    useForm({
      mode: "onChange",
    });
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const userType = localStorage.getItem("usertype");

  const passCodeValidation = {
    required: "PASSCODE_REQUIRED",
    pattern: {
      value: /^[0-9]{4}$/,
      message: "ENTER_VALID_PASSCODE",
    },
    minLength: {
      value: 4,
      message: "PASSCODE_MINIMUM_4_DIGIT",
    },
    maxLength: {
      value: 4,
      message: "PASSCODE_MAXIMUM_4_DIGIT",
    },
    // required: "PASSCODE_REQUIRED",
    // pattern: {
    //   value: /^[0-9]{4}(?:-[0-9]{4})?$/,
    //   message: "ENTER_VALID_PASSCODE",
    // },
    // minLength: {
    //   value: 4,
    //   message: "PASSCODE_MINIMUM_4_DIGIT",
    // },
    // maxLength: {
    //   value: 4,
    //   message: "PASSCODE_MAXIMUM_4_DIGIT",
    // },
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("pass_code", data.passCode);
    formData.append("password", data.password);
    formData.append("confirm_password", data.confirmPassword);
    formData.append("user_type", userType);
    setIsLoading(true);
    IndependicareApi.resetPassword(formData)
      .then((res) => {
        setIsLoading(false);
        const responseCode = STATUS_MSG[res?.data?.code];
        if (res?.code === STATUS_CODE.SUCCESS) {
          Toster(t("RESET_PASSWORD_SUCCESSFULLY"), "success");
          navigate("/signin");
        } else if (res?.data?.code == STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(res.data.message, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      })
      .catch((error) => {
        Toster(error, "error");
      });
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h3 className="headingContent">{t("RESET_PASSWORD")}</h3>
      <p className="paragraphText">
        {t("PLEASE_FILL_OUT_THIS_FORM_TO_RESET_YOUR_PASSWORD")}
      </p>
      <div className="registerFormsField">
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className={style.userOtpContainerProperty}>
            <OtpInput
              {...register("passCode", ValidationSchema.passCode)}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span></span>}
              renderInput={(props) => (
                <input
                  {...props}
                  className={style.otpInputField}
                  placeholder="x"
                  RegisterName="passCode"
                  register={register}
                  formState={formState}
                />
              )}
            />
          </div>
          {!passCode && (
            <p className={`${style.errorMessage} text-danger`}>
              {t(errors.passCode?.message)}
            </p>
          )} */}
          {/* -------------------------------- */}
          <div className={style.userOtpContainerProperty}>
            <Controller
              name="passCode"
              control={control}
              rules={passCodeValidation}
              render={({ field, fieldState: { error } }) => (
                <>
                  <OtpInput
                    value={field.value}
                    onChange={field.onChange}
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus
                    renderSeparator={<span></span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className={`${style.otpInputField} ${
                          error ? "is-invalid" : ""
                        }`}
                        placeholder="x"
                      />
                    )}
                  />
                  {/* <div className={styl}> */}
                  {/* {error && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {error.message}
                </Form.Control.Feedback>
              )} */}
                  {error && (
                    <div className={style.errorMessage}>
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {t(error.message)}
                      </Form.Control.Feedback>
                    </div>
                  )}
                  {/* </div> */}
                </>
              )}
            />
          </div>
          {/* ---------------------------------- */}
          <CustomPasswordInputField
            type="Password"
            PlaceHolder={t("ENTER_NEW_PASSWORD")}
            RegisterName="password"
            register={register}
            formState={formState}
            label={t("NEW_PASSWORD")}
          />
          <CustomPasswordInputField
            type="Password"
            PlaceHolder={t("ENTER_CONFIRM_PASSWORD")}
            RegisterName="confirmPassword"
            register={register}
            formState={formState}
            label={t("CONFIRM_PASSWORD")}
          />
          <div className="buttonContent pt-4">
            <Row>
              <Col sm={8} md={6} className="textCol">
                <p className="PreviousLink">
                  <Link className="customLink" to={"/signin"}>
                    {/* {`< `} */}
                    <KeyboardArrowLeftIcon />
                    {t("BACK_TO_SIGN_IN")}
                  </Link>
                </p>
              </Col>
              <Col
                sm={4}
                md={6}
                className="d-flex justify-content-end setButton"
              >
                <Button
                  as="input"
                  type="submit"
                  // value={t("SEND_REST_LINK")}
                  value={t("RESET_PASSWORD")}
                  className="buttonProperty"
                />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
}
export default ResetPassword;
