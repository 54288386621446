import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import styles from "./AddTag.module.css";
import { Image } from "react-bootstrap";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import audioImage from "../../Assests/Images/Vector@2x.png";
import audioSpeaker from "../../Assests/Images/audioSpeaker.png";
import moment from "moment";
import IndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import PlayAudioBtn from "../../Assests/Images/PlayAudioBtn.png";
import ReactAudioPlayer from "react-audio-player";
import Loader from "../LoaderComponent/LoaderComponent";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// dayjs-config.js (if needed)
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import CustomSearchField from "../CustomComponent/CustomSearchField/CustomSearchField";
import useDebounce from "../../Helpers/Debounce";
import { debounce } from "lodash";
import { clearUserState } from "../../redux/slices/userSlice";

import axios from "axios";
import { LIST_TYPE } from "../../Utils/Constant";

const AddTag = ({
  perLoad,
  perOpt,
  locOpt,
  predOpt,
  levelOpt,
  showModal,
  handleCloseModal,
  getList,
  itemData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    errors,
    control,
    className,
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [personOption, setPersonOption] = useState();
  const locationType = locOpt;
  const [subLocationType, setSubLocationType] = useState([]);
  const predefinedOpt = predOpt;
  const levelOption = levelOpt;

  const [isLoading, setIsLoading] = useState(false);
  const [tagId, setTagId] = useState("");
  const [tagTitle, setTagTitle] = useState("");
  const [selectLocation, setSelectLocation] = useState("");
  const [selectSubLocation, setSelectSubLocation] = useState();

  const [notes, setNotes] = useState("");

  let schedule_obj = {
    start_time: null,
    end_time: null,
    for_start_time: null,
    for_end_time: null,
    // tts_prompt: "",
    // use_tts_prompt: 1,
    reminder_status: 1,
    reminders: [
      {
        isPredifinedSelect: 1,
        reminder_id: "",
        custom_reminder: "",
        custom_level: "",
        reminder: null,
        level: 0,
      },
    ],
    time_specific: true,
    // isFileChanged: false,
    // reminder_audio: null,
    // fileName: "",
  };
  const [scheduleData, setScheduleData] = useState([schedule_obj]);
  const [deletedIds, setDeletedIds] = useState([]);

  const [showPerson, setShowPerson] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState("");
  const [selectedPersonId, setSelectedPersonId] = useState("");

  const [currentPageForPerson, setCurrentPageForPerson] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadMore, setIsLoadMore] = useState(false);
  const listInnerRef = useRef();

  useEffect(() => {
    if (itemData) {
      setDeletedIds([]);
      setIsLoadMore(perLoad);
      setCurrentPageForPerson(1);
      setPersonOption(perOpt);
      setSelectedPersonId(itemData?.person_id);
      setSelectedPerson(itemData?.person_name);
      setTagId(itemData?.tag_uniq_id);
      setSelectLocation(itemData?.location_id);
      setNotes(itemData?.notes);
      setScheduleData(itemData?.schedules);
      setTagTitle(itemData?.tag_title);

      setValue("tagid", itemData?.tag_uniq_id);
      setValue("location", itemData?.location_id);
      setValue("sublocation", itemData?.sub_location_id);
      setValue("notes", itemData?.notes);
      setValue("tagTitle", itemData?.tag_title);

      const tempArray = JSON.parse(JSON.stringify(itemData?.schedules));
      for (let j = 0; j < tempArray?.length; j++) {
        // setValue("useprompt" + j, tempArray[j]?.tts_prompt);
        setValue("predifined" + j + "0", tempArray[j]?.reminder_id);
        setValue("level" + j + "0", tempArray[j]?.custom_level);
        setValue("description" + j + "0", tempArray[j]?.custom_reminder);
        const [hours, minutes, seconds] = tempArray[j]?.start_time
          ?.split(":")
          .map(Number);
        const startDate = new Date();
        startDate.setHours(hours, minutes, seconds, 0);

        const [hrs, mins, sec] = tempArray[j]?.end_time?.split(":").map(Number);
        const endDate = new Date();
        endDate.setHours(hrs, mins, sec, 0);
        tempArray[j].for_start_time = startDate;
        tempArray[j].for_end_time = endDate;
        if (tempArray[j]?.reminders[0]?.reminder_id == "") {
          tempArray[j].reminders[0].isPredifinedSelect = 0;
        } else {
          tempArray[j].reminders[0].isPredifinedSelect = 1;
        }
      }
      setScheduleData([...tempArray]);
      getSubLocationTypeList(itemData?.location_id);
      setSelectSubLocation(itemData?.sub_location_id);
    } else {
      setDeletedIds([]);
      setCurrentPageForPerson(1);
      setIsLoadMore(perLoad);
      setPersonOption(perOpt);
      setSelectedPersonId("");
      setSelectedPerson("");
      setTagId("");
      setSelectLocation("");
      setNotes("");
      setScheduleData("");
      getSubLocationTypeList("");
      setSelectSubLocation("");
      setScheduleData([schedule_obj]);
      setTagTitle("");

      setValue("tagid", "");
      setValue("tag", "");
      setValue("location", "");
      setValue("sublocation", "");
      setValue("taglocation", "");
      setValue("notes", "");
      setValue("tagTitle", "");
      for (let j = 0; j < scheduleData?.length; j++) {
        // setValue("prompt" + j, "");
        // setValue("useprompt" + j, "");
        setValue("predifined" + j + "" + "0", "");
        setValue("level" + j + "" + "0", "");
        setValue("description" + j + "" + "0", "");
      }
      setIsPersonError("");
      setIsTagIdError("");
      setIsLocationTypeError("");
      setIsSubLocationTypeError("");
      setIsStartTimeError("");
      setIsStopTimeError("");
      setIsTTSPromptError("");
      setIsPredefinedError("");
      setIsLevelError("");
      setIsCustomTextError("");
      setIsReminderAudioError("");
      setIsNotesError("");
      setErrorIndex(null);
      setErrorTagTitle("");
    }
  }, [itemData, perLoad, perOpt]);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);

  const playAudio = () => {
    setShowAudioModal(true);
  };

  const handleCloseAudioModal = () => setShowAudioModal(false);

  const [isPersonError, setIsPersonError] = useState("");
  const [isTagIdError, setIsTagIdError] = useState("");
  const [isLocationTypeError, setIsLocationTypeError] = useState("");
  const [isSubLocationTypeError, setIsSubLocationTypeError] = useState("");
  const [isStartTimeError, setIsStartTimeError] = useState("");
  const [isStopTimeError, setIsStopTimeError] = useState("");
  const [isTTSPromptError, setIsTTSPromptError] = useState("");
  const [isPredefinedError, setIsPredefinedError] = useState("");
  const [isLevelError, setIsLevelError] = useState("");
  const [isCustomTextError, setIsCustomTextError] = useState("");
  const [isReminderAudioError, setIsReminderAudioError] = useState("");
  const [isNotesError, setIsNotesError] = useState("");
  const [errorIndex, setErrorIndex] = useState();
  const [errorTagTitle, setErrorTagTitle] = useState();

  const apiPayLoads = {};
  const onSubmit = (data) => {
    setIsPersonError("");
    setIsTagIdError("");
    setIsLocationTypeError("");
    setIsSubLocationTypeError("");
    setIsStartTimeError("");
    setIsStopTimeError("");
    setIsTTSPromptError("");
    setIsPredefinedError("");
    setIsLevelError("");
    setIsCustomTextError("");
    setIsReminderAudioError("");
    setIsNotesError("");
    setErrorIndex(null);
    setErrorTagTitle("");

    const t_Id = data.tagid?.trim();
    const t_Title = data.tagTitle?.trim();

    if (selectedPersonId == "") {
      setIsPersonError(t("PLEASE_SELECT_PERSON"));
      return;
    }
    if (!t_Id) {
      setIsTagIdError(t("PLEASE_ENTER_TAG_ID"));
      return;
    }
    if (t_Id?.length < 2) {
      setIsTagIdError(t("Tag ID may have minimum 2 characters"));
      return;
    }
    if (t_Id?.length > 100) {
      setIsTagIdError(t("Tag ID may have maximum 100 characters"));
      return;
    }
    if (!t_Title) {
      setErrorTagTitle(t("PLEASE_ENTER_TAG_TITLE"));
      return;
    }
    if (t_Title?.length < 2) {
      setErrorTagTitle(t("TAG_TITLE_MAY_HAVE_MINIMUM_2_CHARACTERS"));
      return;
    }
    if (t_Title?.length > 100) {
      setErrorTagTitle(t("TAG_TITLE_SHOULD_NOT_MORE_THAN_50_CHARACTERS"));
      return;
    }
    if (data?.location == "") {
      setIsLocationTypeError(t("PLEASE_SELECT_LOCATION_TYPE"));
      return;
    }
    if (data?.sublocation == "") {
      setIsSubLocationTypeError(t("PLEASE_SELECT_SUB_LOCATION_TYPE"));
      return;
    }
    apiPayLoads.person_id = selectedPersonId;
    apiPayLoads.tag_uniq_id = data?.tagid?.trim();
    apiPayLoads.location_id = data?.location;
    apiPayLoads.sub_location_id = data?.sublocation;
    apiPayLoads.tag_title = data?.tagTitle?.trim();

    if (itemData && deletedIds?.length > 0) {
      const ids = deletedIds?.join(",");
      apiPayLoads.deleted_ids = ids;
    }

    const scheduleJson = JSON.parse(JSON.stringify(scheduleData));
    for (let i = 0; i < scheduleJson?.length; i++) {
      // let newUsePrompt = "useprompt" + i;
      let preval = "predifined" + i + "" + "0";
      let lev = "level" + i + "" + "0";
      let des = "description" + i + "" + "0";
      // let prompt_check = data[newUsePrompt]?.trim();

      if (
        scheduleJson[i]?.start_time == null ||
        scheduleJson[i]?.end_time == null
      ) {
        // Toster(t("Please select valid time interval"), "error");
        setErrorIndex(i);
        setIsStartTimeError(t("Please select valid time interval"));
        setIsStopTimeError(t("Please select valid time interval"));
        return;
      }
      for (let j = i + 1; j < scheduleData?.length; j++) {
        if (
          (scheduleJson[i]?.start_time >= scheduleData[j]?.start_time &&
            scheduleJson[i]?.start_time <= scheduleData[j]?.end_time) ||
          (scheduleJson[i]?.end_time >= scheduleData[j]?.start_time &&
            scheduleJson[i]?.end_time <= scheduleData[j]?.end_time)
        ) {
          // Toster(t("Time interval should not be overlapped"), "error");
          setErrorIndex(j);
          setIsStartTimeError(t("Time interval should not be overlapped"));
          setIsStopTimeError(t("Time interval should not be overlapped"));
          return;
        }
      }

      if (scheduleJson[i]?.start_time == null) {
        setErrorIndex(i);
        setIsStartTimeError(t("PLEASE_SELECT_START_TIME"));

        return;
      }
      if (scheduleJson[i]?.end_time == null) {
        setErrorIndex(i);
        setIsStopTimeError(t("PLEASE_SELECT_STOP_TIME"));
        return;
      }
      if (scheduleJson[i]?.end_time <= scheduleJson[i]?.start_time) {
        setErrorIndex(i);
        setIsStopTimeError(t("Stop time should be greater than start time"));
        return;
      }
      // if (!prompt_check) {
      //   setErrorIndex(i);
      //   setIsTTSPromptError(t("PLEASE_ENTER_TTS_FOR_PROMPT"));
      //   return;
      // }
      // if (prompt_check?.length < 2) {
      //   setErrorIndex(i);
      //   setIsTTSPromptError(t("TTS prompt may have minimum 2 characters"));
      //   return;
      // }
      // if (prompt_check?.length > 100) {
      //   setErrorIndex(i);
      //   setIsTTSPromptError(t("TTS prompt may have maximum 100 characters"));
      //   return;
      // }
      // scheduleJson[i].tts_prompt = data[newUsePrompt];

      if (scheduleJson[i]?.reminders[0]?.isPredifinedSelect) {
        if (
          scheduleJson[i]?.reminders[0]?.reminder_id == "" ||
          scheduleJson[i]?.reminders[0]?.reminder_id == undefined
        ) {
          setErrorIndex(i);
          setIsPredefinedError(t("PLEASE_SELECT_REMINDER"));
          return;
        }
        scheduleJson[i].reminders[0].reminder_id =
          scheduleJson[i]?.reminders[0]?.reminder_id;
      } else {
        if (data[lev] == "") {
          setErrorIndex(i);
          setIsLevelError(t("PLEASE_SELECT_CUSTOM_LEVEL"));
          return;
        }
        const check_custRem =
          scheduleJson[i]?.reminders[0]?.custom_reminder?.trim();
        if (!check_custRem) {
          setErrorIndex(i);
          setIsCustomTextError(t("PLEASE_ENTER_CUSTOM_TEXT"));
          return;
        }
        if (check_custRem?.length < 2) {
          setErrorIndex(i);
          setIsCustomTextError(
            t("Custom description may have minimum 2 characters")
          );
          return;
        }
        if (check_custRem?.length > 200) {
          setErrorIndex(i);
          setIsCustomTextError(
            t("Custom description may have maximum 200 characters")
          );
          return;
        }
        scheduleJson[i].reminders[0].custom_level = data[lev];
      }
      // if (scheduleJson[i]?.reminder_audio == null) {
      //   setErrorIndex(i);
      //   setIsReminderAudioError(t("PLEASE_SELECT_AUDIO_REMINDER"));
      //   return;
      // }
    }

    const check_notes = notes?.trim();

    // if (!check_notes) {
    //   setIsNotesError(t("PLEASE_ENTER_NOTES"));
    //   return;
    // }
    // if (check_notes?.length < 2) {
    //   setIsNotesError(t("Notes may have minimum 2 characters"));
    //   return;
    // }
    // if (check_notes?.length > 300) {
    //   setIsNotesError(t("Notes may have maximum 300 characters"));
    //   return;
    // }
    if (check_notes?.length > 0) {
      if (check_notes.length < 2) {
        setIsNotesError(t("Notes must have at least 2 characters"));
        return;
      }
      if (check_notes.length > 300) {
        setIsNotesError(t("Notes may have a maximum of 300 characters"));
        return;
      }
    }
    setIsNotesError("");
    apiPayLoads.notes = notes?.trim();

    const check_title = tagTitle?.trim();

    if (!check_title) {
      setErrorTagTitle(t("PLEASE_ENTER_TAG_TITLE"));
      return;
    }
    if (check_title?.length < 2) {
      setErrorTagTitle(t("TAG_TITLE_MAY_HAVE_MINIMUM_2_CHARACTERS"));
      return;
    }
    if (check_title?.length > 50) {
      setErrorTagTitle(t("TAG_TITLE_SHOULD_NOT_MORE_THAN_50_CHARACTERS"));
      return;
    }

    apiPayLoads.tag_title = tagTitle?.trim();
    var form_data = new FormData();
    // for (let j = 0; j < scheduleData.length; j++) {
    //   const fileName = j + "_" + scheduleData[j]?.fileName;
    //   if (scheduleData[j]?.isFileChanged) {
    //     form_data.append(
    //       "reminder_audio",
    //       scheduleData[j]?.reminder_audio,
    //       fileName
    //     );
    //   } else {
    //     form_data.append("reminder_audio", "");
    //   }
    // }

    for (let index = 0; index < scheduleJson.length; index++) {
      if (scheduleJson[index].reminders[0]?.isPredifinedSelect == 0) {
        scheduleJson[index].reminders[0].reminder_id = "";
      } else {
        scheduleJson[index].reminders[0].custom_reminder = "";
        scheduleJson[index].reminders[0].custom_level = "";
      }
      delete scheduleJson[index]["for_start_time"];
      delete scheduleJson[index]["for_end_time"];
      delete scheduleJson[index]["reminder_audio"];
      delete scheduleJson[index]["tts_prompt"];
      delete scheduleJson[index]["use_tts_prompt"];
      delete scheduleJson[index]["time_specific"];
      delete scheduleJson[index].reminders[0]["isPredifinedSelect"];
      delete scheduleJson[index].reminders[0]["reminder"];
      delete scheduleJson[index].reminders[0]["level"];
      delete scheduleJson[index]["prompt_intro_tts"];
      // tts_prompt: "",
      // use_tts_prompt: 1,
      // delete scheduleJson[index]["fileName"];
    }
    const newObj = {};
    newObj.schedule_data = scheduleJson;
    apiPayLoads.schedule_json = JSON.stringify(newObj);

    for (var key in apiPayLoads) {
      form_data.append(key, apiPayLoads[key]);
    }

    if (itemData?.tag_id) {
      form_data.append("tagId", itemData?.tag_id);
      updateTag(itemData?.tag_id, form_data);
      return;
    } else {
      handleAddTag(form_data);
      return;
    }
  };

  const token = useSelector((state) => state.user.userToken);

  const handleAddTag = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.addTag(token, payLoads).then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      setIsLoading(false);
      if (res?.code === STATUS_CODE.SUCCESS) {
        Toster(t("ADD_TAG_SUCCESFULLY"), "success");
        resetForm();
        handleCloseModal();
        getList();
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(responseCode, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };
  const updateTag = (tagId, payLoads) => {
    setIsLoading(true);
    IndependicareApi.updateTag(token, tagId, payLoads).then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      setIsLoading(false);
      if (res?.code === STATUS_CODE.SUCCESS) {
        Toster(t("TAG_UPDATE_SUCCESFULLY"), "success");
        resetForm();
        handleCloseModal();
        getList();
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  const resetForm = () => {
    setSelectedPersonId("");
    setSelectedPerson("");
    setTagId("");
    setTagTitle("");
    setSelectLocation("");
    setNotes("");
    setScheduleData("");
    getSubLocationTypeList("");
    setSelectSubLocation("");
    reset({
      tagid: "",
      tag: "",
      tagTitle: "",
      location: "",
      sublocation: "",
      taglocation: "",
      notes: "",
    });
    setScheduleData([schedule_obj]);
    for (let j = 0; j < scheduleData?.length; j++) {
      // setValue("prompt" + j, "");
      // setValue("useprompt" + j, "");
      setValue("predifined" + j + "" + "0", "");
      setValue("level" + j + "" + "0", "");
      setValue("description" + j + "" + "0", "");
    }

    handleCloseModal();
  };

  const handleTagIdChange = (e) => {
    setTagId(e.target.value);
  };

  const handleTagTitleChange = (e) => {
    setTagTitle(e.target.value);
  };

  const handleSelectChangeLocationType = (event) => {
    setSelectLocation(event.target.value);
    getSubLocationTypeList(event.target.value);
  };

  const getSubLocationTypeList = (id) => {
    IndependicareApi.getSubLocationType(
      token,
      {
        location_type_id: id,
      },
      LIST_TYPE.ACTIVE
    ).then((res) => {
      setSubLocationType(res?.data?.subloactionType);
    });
  };

  const handleSelectChangeSubLocationType = (event) => {
    setSelectSubLocation(event.target.value);
  };

  const isValidStartTime = (currenTime, currentIndex) => {
    if (scheduleData[currentIndex]?.start_time) {
      for (let i = 0; i < scheduleData?.length; i++) {
        if (
          i !== currentIndex &&
          scheduleData[i]?.start_time != null &&
          scheduleData[i]?.end_time != null
        ) {
          if (
            currenTime == scheduleData[i]?.start_time ||
            currenTime == scheduleData[i].end_time
          ) {
            return false;
          }
          if (
            !(
              (currenTime < scheduleData[i]?.start_time &&
                currenTime < scheduleData[i].end_time) ||
              (currenTime > scheduleData[i]?.start_time &&
                currenTime > scheduleData[i].end_time)
            )
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };
  const isValidEndTime = (currenTime, currentIndex) => {
    const start_time = scheduleData[currentIndex]?.start_time;
    if (scheduleData[currentIndex]?.end_time) {
      for (let i = 0; i < scheduleData?.length; i++) {
        if (
          i !== currentIndex &&
          scheduleData[i]?.start_time != null &&
          scheduleData[i]?.end_time != null
        ) {
          if (
            currenTime === scheduleData[i]?.start_time ||
            currenTime === scheduleData[i]?.end_time
          ) {
            return false;
          }
          if (
            start_time > scheduleData[i]?.start_time &&
            start_time > scheduleData[i]?.end_time
          ) {
            if (
              currenTime <= scheduleData[i]?.start_time ||
              currenTime <= scheduleData[i]?.end_time
            ) {
              return false;
            }
          }
          if (
            start_time < scheduleData[i]?.start_time &&
            start_time < scheduleData[i]?.end_time
          ) {
            if (
              currenTime >= scheduleData[i]?.start_time ||
              currenTime >= scheduleData[i]?.end_time
            ) {
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const handleClosePerson = () => {
    setShowPerson(false);
  };
  const handleShowPerson = () => {
    setShowPerson(true);
  };

  const debouncedSearchPerson = useDebounce((term) => {
    getPersonList(1, term);
  }, 500);

  const getPersonList = (currentPageForPerson, searchQuery) => {
    IndependicareApi.getPersonList(
      token,
      currentPageForPerson,
      searchQuery,
      LIST_TYPE.ACTIVE
    ).then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];

      if (res?.code === STATUS_CODE.SUCCESS) {
        const newPersons = res?.data?.person_list;
        if (currentPageForPerson === 1) {
          setPersonOption(newPersons);
        } else {
          setPersonOption((prevPersons) => [...prevPersons, ...newPersons]);
        }
        setCurrentPageForPerson(currentPageForPerson);
        setIsLoadMore(res?.data?.loadMore);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  const handleScroll = () => {
    if (!isLoadMore) return;

    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        onPageChangePerson(currentPageForPerson + 1);
      }
    }
  };
  const onPageChangePerson = (pageNumber) => {
    getPersonList(pageNumber, searchQuery);
  };
  return (
    <div>
      {isLoading && <Loader />}
      <Modal show={showPerson} onHide={handleClosePerson}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className="setModelTitleProperty">
            {t("SELECT_PERSON")}
          </Modal.Title>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleClosePerson}
          />
          {/* <CancelIcon
                    className="closeIconProperty"
                    onClick={closeModelPerson}
                  /> */}
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-center">
                <CustomSearchField
                  value={searchQuery}
                  setSearchQuery={(val) => {
                    setSearchQuery(val);
                    debouncedSearchPerson(val);
                  }}
                />
              </div>
              <div
                className={styles.personListContainer}
                ref={listInnerRef}
                onScroll={debounce(handleScroll, 100)}
              >
                {personOption && personOption?.length > 0 ? (
                  personOption?.map((option) => (
                    <div
                      key={option.id}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <Form.Label htmlFor={`person-${option?.id}`}>
                        {option?.person_name}
                      </Form.Label>
                      <Form.Check
                        key={option.id}
                        type="checkbox"
                        id={`person-${option?.id}`}
                        value={option?.id}
                        checked={selectedPersonId == option?.id ? true : false}
                        onChange={() => {
                          setSelectedPersonId(option?.id);
                          setSelectedPerson(option?.person_name);
                          handleClosePerson();
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div className="d-flex justify-content-center p-2">
                    <p>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
              </div>
            </div>
          </Form.Group>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className="setModelTitleProperty">
            {itemData?.person_id ? t("UPDATE_TAG") : t("ADD_TAG")}
          </Modal.Title>
          <CancelIcon
            className="closeIconProperty"
            onClick={() => {
              setSelectedPerson("");
              setSelectedPersonId("");
              handleCloseModal();
            }}
          />
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className={`border-none ${styles.personField}`}>
              <Form.Group className="mb-3">
                <Form.Label className={styles.personLabel}>
                  {t("PERSON")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={selectedPerson}
                  placeholder={t("SELECT_PERSON")}
                  onClick={handleShowPerson}
                  readOnly
                  className={`${styles?.inputStyle}`}
                />
                {isPersonError != "" ? (
                  <p style={{ color: "red", fontSize: 12 }}>{isPersonError}</p>
                ) : null}
              </Form.Group>
            </div>

            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_TAG_ID")}
              RegisterName="tagid"
              register={register}
              formState={formState}
              label={t("TAG_ID")}
              className={styles.addDeviceProperty}
              value={tagId}
              defaultValue={tagId}
              onChange={handleTagIdChange}
            />
            {isTagIdError != "" ? (
              <p style={{ color: "red", fontSize: 12 }}>{isTagIdError}</p>
            ) : null}
            {/* for the tag title input field */}
            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_TAG_TITLE")}
              RegisterName="tagTitle"
              register={register}
              formState={formState}
              label={t("TAG_TITLE")}
              className={styles.addDeviceProperty}
              value={tagTitle}
              defaultValue={tagTitle}
              onChange={handleTagTitleChange}
            />
            {errorTagTitle != "" ? (
              <p style={{ color: "red", fontSize: 12 }}>{errorTagTitle}</p>
            ) : null}

            <div style={{ height: 15 }} />
            <CustomSelectFields
              PlaceHolder={t("SELECT_LOCATION_TYPE")}
              RegisterName="location"
              register={register}
              options={locationType}
              formState={formState}
              label={t("LOCATION_TYPE")}
              value={selectLocation}
              onChange={handleSelectChangeLocationType}
              index={null}
              isfromArray={false}
            />
            {isLocationTypeError != "" ? (
              <p style={{ color: "red", fontSize: 12 }}>
                {isLocationTypeError}
              </p>
            ) : null}
            <div style={{ height: 15 }} />
            <CustomSelectFields
              PlaceHolder={t("SELECT_SUB_LOCATION_TYPE")}
              RegisterName="sublocation"
              register={register}
              options={subLocationType}
              formState={formState}
              label={t("SUB_LOCATION_TYPE")}
              value={selectSubLocation}
              onChange={handleSelectChangeSubLocationType}
              index={null}
              isfromArray={false}
            />
            {isSubLocationTypeError != "" ? (
              <p style={{ color: "red", fontSize: 12 }}>
                {isSubLocationTypeError}
              </p>
            ) : null}
            <div>
              {scheduleData?.map((item, index) => {
                const remind = item?.reminders;
                const st =
                  item?.for_start_time != null
                    ? dayjs(item?.for_start_time)
                    : null;
                const et =
                  item?.end_time != null ? dayjs(item?.for_end_time) : null;
                return (
                  <div
                    style={{
                      display: "flow",
                      marginTop: 20,
                      flexDirection: "column",
                      border: "1px solid #DCDCDC",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      borderRadius: 6,
                    }}
                  >
                    {scheduleData?.length > 1 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          position: "absolute",
                          alignSelf: "flex-end",
                          width: "90%",
                          margin: "5px",
                        }}
                      >
                        <div />
                        <CancelIcon
                          className="closeIconProperty"
                          onClick={async () => {
                            if (item?.schedule_id) {
                              setDeletedIds((prev) => [
                                ...prev,
                                item?.schedule_id,
                              ]);
                            }

                            setErrorIndex(null);
                            let filtArray = scheduleData?.filter(
                              (_, ind) => index != ind
                            );
                            setScheduleData([...filtArray]);
                          }}
                        />
                      </div>
                    ) : null}
                    <div style={{ height: 10 }} />
                    <label>{t("START_TIME")}</label>
                    <div style={{ height: 5 }} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        sx={{
                          width: "100%",
                        }}
                        value={st}
                        onChange={(newTime) => {
                          const form_time = newTime.format("HH:mm:ss");
                          const changetime = scheduleData?.map((obj, indx) =>
                            indx == index
                              ? {
                                  ...obj,
                                  start_time: form_time,
                                  for_start_time: newTime,
                                }
                              : obj
                          );
                          setScheduleData([...changetime]);
                          return;
                        }}
                        onAccept={(newTime) => {
                          const form_time = newTime.format("HH:mm:ss");
                          if (isValidStartTime(form_time, index)) {
                            const changetime = scheduleData?.map((obj, indx) =>
                              indx == index
                                ? {
                                    ...obj,
                                    start_time: form_time,
                                    for_start_time: newTime,
                                    end_time:
                                      form_time > obj?.end_time
                                        ? null
                                        : obj?.end_time,
                                    for_end_time:
                                      form_time > obj?.end_time
                                        ? null
                                        : obj?.for_end_time,
                                  }
                                : obj
                            );
                            setScheduleData([...changetime]);
                            return;
                          } else {
                            const changetime = scheduleData?.map((obj, indx) =>
                              indx == index
                                ? {
                                    ...obj,
                                    start_time: null,
                                    for_start_time: null,
                                    end_time: null,
                                    for_end_time: null,
                                  }
                                : obj
                            );
                            setScheduleData([...changetime]);
                            Toster(t("Time already exist"), "error");
                            return;
                          }
                        }}
                        renderInput={(params) => (
                          <input
                            {...params}
                            className="form-control time-picker"
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {isStartTimeError != "" && errorIndex == index ? (
                      <p style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                        {isStartTimeError}
                      </p>
                    ) : null}
                    <div style={{ height: 15 }} />
                    <label>{t("STOP_TIME")}</label>
                    <div style={{ height: 5 }} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        sx={{
                          width: "100%",
                        }}
                        value={et}
                        ampm={true}
                        onChange={(newTime) => {
                          const form_time = newTime.format("HH:mm:ss");
                          const changetime = scheduleData?.map((obj, indx) =>
                            indx == index
                              ? {
                                  ...obj,
                                  end_time: form_time,
                                  for_end_time: newTime,
                                }
                              : obj
                          );
                          setScheduleData([...changetime]);
                          return;
                        }}
                        onAccept={(newTime) => {
                          const form_time = newTime.format("HH:mm:ss");
                          if (
                            isValidEndTime(form_time, index) &&
                            item?.for_start_time != null &&
                            newTime > item?.for_start_time
                          ) {
                            const changetime = scheduleData?.map((obj, indx) =>
                              indx == index
                                ? {
                                    ...obj,
                                    end_time: form_time,
                                    for_end_time: newTime,
                                  }
                                : obj
                            );
                            setScheduleData([...changetime]);
                            return;
                          } else {
                            const changetime = scheduleData?.map((obj, indx) =>
                              indx == index
                                ? {
                                    ...obj,
                                    end_time: null,
                                    for_end_time: null,
                                  }
                                : obj
                            );
                            setScheduleData([...changetime]);
                            if (item?.for_start_time == null) {
                              Toster(
                                t("Please select start time first"),
                                "error"
                              );
                              return;
                            } else if (newTime <= item?.for_start_time) {
                              Toster(
                                t(
                                  "Stop time should be greater than start time"
                                ),
                                "error"
                              );
                              return;
                            } else {
                              Toster(t("Time already exist"), "error");
                              return;
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <input
                            {...params}
                            className="form-control time-picker"
                          />
                        )}
                        onClose={() => {}}
                      />
                    </LocalizationProvider>
                    {isStopTimeError != "" && errorIndex == index ? (
                      <p style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                        {isStopTimeError}
                      </p>
                    ) : null}
                    {/* <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "absolute",
                          width: "89%",
                          marginTop: "5px",
                        }}
                      >
                        <div />
                        <div
                          class="form-check form-switch"
                          onClick={() => {
                            const val = item?.use_tts_prompt == 1 ? 0 : 1;
                            const changeRemSta = scheduleData?.map(
                              (obj, indx) =>
                                indx == index
                                  ? { ...obj, use_tts_prompt: val }
                                  : obj
                            );
                            setScheduleData([...changeRemSta]);
                          }}
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={item?.use_tts_prompt}
                            style={{
                              backgroundColor: item?.use_tts_prompt
                                ? "#5DA128"
                                : "#959595",
                            }}
                          />
                        </div>
                      </div>
                      <CustomInputFieldsModelForm
                        type="text"
                        PlaceHolder={t("ENTER_PROMPT_INTRO_TTS")}
                        RegisterName={"useprompt" + index}
                        register={register}
                        formState={formState}
                        label={t("USE_TTS_FOR_PROMPT")}
                        className={styles.addDeviceProperty}
                        value={item?.tts_prompt}
                        onChange={(e) => {
                          const newArr = scheduleData?.map((obj, idx) =>
                            idx == index
                              ? { ...obj, tts_prompt: e?.target?.value }
                              : obj
                          );
                          setScheduleData(newArr);
                        }}
                      />
                    </div> */}
                    {/* {isTTSPromptError != "" && errorIndex == index ? (
                      <p style={{ color: "red", fontSize: 12 }}>
                        {isTTSPromptError}
                      </p>
                    ) : null} */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <label>{t("REMINDER")}</label>
                      <div
                        class="form-check form-switch"
                        onClick={() => {
                          const val = item?.reminder_status == 1 ? 0 : 1;
                          const changeRemSta = scheduleData?.map((obj, indx) =>
                            indx == index
                              ? { ...obj, reminder_status: val }
                              : obj
                          );
                          setScheduleData([...changeRemSta]);
                        }}
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={item?.reminder_status}
                          style={{
                            backgroundColor: item?.reminder_status
                              ? "#5DA128"
                              : "#959595",
                          }}
                          input
                        />
                      </div>
                    </div>
                    {remind?.map((childItem, childInd) => (
                      <>
                        {remind?.length > 1 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              position: "absolute",
                              alignSelf: "flex-end",
                              width: "89%",
                              marginTop: 10,
                            }}
                          >
                            <div />
                            <CancelIcon
                              className="closeIconProperty"
                              onClick={() => {
                                const filtArr = remind?.filter(
                                  (_, ind) => childInd != ind
                                );
                                const newMapedArr = scheduleData?.map(
                                  (obj, idx) =>
                                    idx == index
                                      ? { ...obj, reminders: [...filtArr] }
                                      : obj
                                );
                                setScheduleData(newMapedArr);
                              }}
                            />
                          </div>
                        ) : null}
                        <div style={{ height: remind?.length > 1 ? 30 : 5 }} />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "47%",
                              height: "50px",
                              background: "#FAFAFA",
                              border: "1px solid #E3E3E3",
                              borderRadius: "6px",
                              paddingLeft: "10px",
                              paddingRight: "5px",
                            }}
                          >
                            <label>{t("PREDEFINED")}</label>
                            <div
                              class="form-check"
                              onClick={() => {
                                const changeRemSelection = remind?.map(
                                  (obj, indx) =>
                                    indx == childInd
                                      ? { ...obj, isPredifinedSelect: 1 }
                                      : obj
                                );
                                const newMapedArr = scheduleData?.map(
                                  (obj, idx) =>
                                    idx == index
                                      ? {
                                          ...obj,
                                          reminders: [...changeRemSelection],
                                        }
                                      : obj
                                );
                                setScheduleData([...newMapedArr]);
                              }}
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name={"exampleRadios5" + index + childInd}
                                id={"exampleRadios5" + index + childInd}
                                value={"option5" + index + childInd}
                                checked={childItem?.isPredifinedSelect}
                                style={{
                                  backgroundColor: childItem?.isPredifinedSelect
                                    ? "#5DA128"
                                    : "",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "47%",
                              height: "50px",
                              background: "#FAFAFA",
                              border: "1px solid #E3E3E3",
                              borderRadius: "6px",
                              paddingLeft: "10px",
                              paddingRight: "5px",
                            }}
                          >
                            <label>{t("CUSTOM")}</label>
                            <div
                              class="form-check"
                              onClick={() => {
                                const changeRemSelection = remind?.map(
                                  (obj, indx) =>
                                    indx == childInd
                                      ? { ...obj, isPredifinedSelect: 0 }
                                      : obj
                                );
                                const newMapedArr = scheduleData?.map(
                                  (obj, idx) =>
                                    idx == index
                                      ? {
                                          ...obj,
                                          reminders: [...changeRemSelection],
                                        }
                                      : obj
                                );
                                setScheduleData([...newMapedArr]);
                              }}
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name={"exampleRadio" + index + childInd}
                                id={"exampleRadio" + index + childInd}
                                value={"option6" + index + childInd}
                                checked={
                                  childItem?.isPredifinedSelect == 1
                                    ? false
                                    : true
                                }
                                style={{
                                  backgroundColor:
                                    childItem?.isPredifinedSelect == 1
                                      ? ""
                                      : "#5DA128",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ height: 15 }} />

                        {childItem?.isPredifinedSelect ? (
                          <>
                            <CustomSelectFields
                              PlaceHolder={t("SELECT_PREDIFINED")}
                              RegisterName={"predifined" + index + "" + "0"}
                              register={register}
                              options={predefinedOpt}
                              formState={formState}
                              label={t("PREDEFINED")}
                              index={index}
                              isfromArray={true}
                              defaultValue={childItem?.reminder_id}
                              value={childItem?.reminder_id}
                              onChange={(e) => {
                                const changelevel = remind?.map((obj, indx) =>
                                  indx == childInd
                                    ? { ...obj, reminder_id: e?.target?.value }
                                    : obj
                                );
                                const newArray = scheduleData?.map((obj, idx) =>
                                  idx == index
                                    ? {
                                        ...obj,
                                        reminders: [...changelevel],
                                      }
                                    : obj
                                );
                                setScheduleData([...newArray]);
                              }}
                            />

                            {isPredefinedError != "" && errorIndex == index ? (
                              <p style={{ color: "red", fontSize: 12 }}>
                                {isPredefinedError}
                              </p>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <CustomSelectFields
                              PlaceHolder={t("SELECT_LEVEL")}
                              RegisterName={"level" + index + "" + childInd}
                              register={register}
                              options={levelOption}
                              formState={formState}
                              label={t("LEVEL")}
                              index={index}
                              isfromArray={true}
                              value={childItem?.custom_level}
                              defaultValue={childItem?.custom_level}
                              onChange={(e) => {
                                const changelevel = remind?.map((obj, indx) =>
                                  indx == childInd
                                    ? { ...obj, custom_level: e?.target?.value }
                                    : obj
                                );
                                const newArray = scheduleData?.map((obj, idx) =>
                                  idx == index
                                    ? {
                                        ...obj,
                                        reminders: [...changelevel],
                                      }
                                    : obj
                                );
                                setScheduleData([...newArray]);
                              }}
                            />
                            {isLevelError != "" && errorIndex == index ? (
                              <p style={{ color: "red", fontSize: 12 }}>
                                {isLevelError}
                              </p>
                            ) : null}
                            <div style={{ height: 15 }} />
                            <CustomDescriptInputField
                              type="text"
                              PlaceHolder={t("ENTER_CUSTOM")}
                              RegisterName={
                                "description" + index + "" + childInd
                              }
                              register={register}
                              formState={formState}
                              label={t("PERSON")}
                              style={{
                                backgroundColor: "red",
                              }}
                              value={childItem?.custom_reminder}
                              defaultValue={childItem?.custom_reminder}
                              onChange={(e) => {
                                const changelevel = remind?.map((obj, indx) =>
                                  indx == childInd
                                    ? {
                                        ...obj,
                                        custom_reminder: e?.target?.value,
                                      }
                                    : obj
                                );
                                const newArray = scheduleData?.map((obj, idx) =>
                                  idx == index
                                    ? {
                                        ...obj,
                                        reminders: [...changelevel],
                                      }
                                    : obj
                                );
                                setScheduleData([...newArray]);
                              }}
                            />

                            {isCustomTextError != "" && errorIndex == index ? (
                              <p style={{ color: "red", fontSize: 12 }}>
                                {isCustomTextError}
                              </p>
                            ) : null}
                          </>
                        )}
                      </>
                    ))}
                    {/* <CustomAudioInputField
                      type="file"
                      PlaceHolder={t("UPLOAD_PROMPT_INTRO_AUDIO")}
                      label={t("PROMPT_INTRO_AUDIO")}
                      RegisterName={"promptIntroAudio" + index}
                      register={register}
                      formState={formState}
                      value={item?.reminder_audio}
                      className={`${styles.addDeviceProperty} ${styles.setTextPlace}`}
                      errorMessage={""}
                      index={index}
                      onChange={(event) => {
                        const file = event.target.files[0];

                        // handleFileChange(file, index);
                        const changefile = scheduleData?.map((obj, indx) =>
                          indx == index
                            ? {
                                ...obj,
                                reminder_audio: file,
                                isFileChanged: true,
                                fileName: file?.name,
                              }
                            : obj
                        );
                        setScheduleData([...changefile]);
                      }}
                    />
                    {item?.reminder_audio ? (
                      <p>
                        <Image
                          src={PlayAudioBtn}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (typeof item?.reminder_audio == "string") {
                              setCurrentAudio(item?.reminder_audio);
                              playAudio();
                              return;
                            } else {
                              const fileURL = URL.createObjectURL(
                                item?.reminder_audio
                              );
                              setCurrentAudio(fileURL);
                              playAudio();
                              return;
                            }
                          }}
                        />
                      </p>
                    ) : null}
                    {isReminderAudioError != "" && errorIndex == index ? (
                      <p style={{ color: "red", fontSize: 12 }}>
                        {isReminderAudioError}
                      </p>
                    ) : null} */}
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <div />
                <span
                  style={{
                    color: "#1D4488",
                    fontSize: "12px",
                    fontWeight: "600",
                    fontFamily: "inter",
                  }}
                  onClick={() => {
                    const arrLength = scheduleData?.length - 1;
                    if (
                      scheduleData[arrLength]?.start_time == null ||
                      scheduleData[arrLength]?.end_time == null
                    ) {
                      Toster(t("Please fill above time fields"), "error");
                      return;
                    }
                    let addNewObj = [...scheduleData, schedule_obj];
                    setScheduleData(addNewObj);
                  }}
                >
                  Add more
                </span>
              </div>
              <div style={{ height: 15 }} />
              <CustomDescriptInputField
                type="text"
                PlaceHolder={t("ENTER")}
                RegisterName={"notes"}
                register={register}
                formState={formState}
                label={t("NOTES")}
                value={notes}
                defaultValue={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
              {isNotesError != "" ? (
                <p style={{ color: "red", fontSize: 12 }}>{isNotesError}</p>
              ) : null}

              <div style={{ height: 30 }} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" className="AddSiteSubmitButton">
              {itemData?.person_id ? t("UPDATE_TAG") : t("ADD_TAG")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showAudioModal} onHide={handleCloseAudioModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className={styles.audioIntro}>
              <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAudio && (
            <ReactAudioPlayer
              src={currentAudio}
              autoPlay
              controls
              style={{ width: "95%" }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
function CustomAudioInputField({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  className,
  value,
  onClick,
  errorMessage,
  index,
}) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <Form.Group className={className} controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={PlaceHolder}
          onClick={handleClick}
          isInvalid={!!formState.errors[RegisterName] || !!errorMessage}
          value={value ? value.name : ""}
          style={{
            paddingLeft: value ? (typeof value == "string" ? 10 : 40) : 10,
          }}
        />
        <input
          type="file"
          accept=".mp3, .wav, .aac, .flac, .ogg, .wma, .aiff, .alac, .m4a, .amr"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={onChange}
        />
        <div className={styles.parentIconButton}>
          <div className={styles.audioImageContainer}>
            <Image
              src={audioImage}
              alt="audioImage"
              className={styles.audioImage}
            />
          </div>
          <div className={styles.browseSpeakerButton}>
            <div className={styles.audioSpeakerContainer}>
              <Image
                src={audioSpeaker}
                alt="audioSpeaker"
                className={styles.audioSpeaker}
              />
            </div>
            <div className={styles.audioButtonContainer}>
              <Button
                type="button"
                className={styles.selectAudioButton}
                onClick={handleClick}
              >
                {t("Browse")}
              </Button>
            </div>
          </div>
        </div>
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message || errorMessage)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}
function CustomInputFieldsModelForm({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  className,
  value,
  defaultValue,
}) {
  return (
    <div>
      <Form.Group className={className} controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          placeholder={PlaceHolder}
          {...register(RegisterName, ValidationSchema[RegisterName])}
          isInvalid={!!formState.errors[RegisterName]}
          onChange={onChange}
          value={value}
        />
      </Form.Group>
    </div>
  );
}

function CustomDescriptInputField({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  className,
  value,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Group>
        {label == t("NOTES") ? <Form.Label>{label}</Form.Label> : null}
        <Form.Control
          type={type}
          placeholder={PlaceHolder}
          onChange={onChange}
          value={value}
          as="textarea"
          style={{
            height: 111,
            fontSize: 15,
          }}
        />
      </Form.Group>
    </div>
  );
}

function CustomSelectFields({
  value,
  onChange,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
  index,
  isfromArray,
  defaultValue,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group>
      {isfromArray ? null : <Form.Label>{label}</Form.Label>}

      <Form.Select
        value={value}
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChange}
      >
        <option className="setTextPlaceholder" value="" class="">
          {PlaceHolder}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.id}>
            {label == t("PERSON")
              ? option.person_name
              : label == t("TAG_TYPE")
              ? option?.title
              : label == t("LOCATION_TYPE")
              ? option?.title
              : label == t("SUB_LOCATION_TYPE")
              ? option?.title
              : label == t("LEVEL")
              ? option?.level
              : label == t("PREDEFINED")
              ? option?.title
              : option.title}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}
export default AddTag;
