//firebase
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken } from "@firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

export const fetchRequestPermission = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem(
              process.env.REACT_APP_FIREBASE_TOKEN,
              currentToken
            );
          } else {
            console.log("Failed to generate the registration token.");
          }
        })

        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
