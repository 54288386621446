import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../CustomComponent/CustomSearchField/CustomSearchField";
import styles from "../../../Components/Settings/TimeMessage/Message.module.css";
import edit from "../../../Assests/Images/edit.png";
import { CustomSelectFields } from "../../CustomComponent/CustomInputFields/CustomInputField";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import Toster from "../../../Toster/Toster";
import { clearUserState } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import deleteImage from "../../../Assests/Images/delete_image.png";
import Loader from "../../LoaderComponent/LoaderComponent";
import { ACTIVE_TYPE, MESSAGE_TYPE } from "../../../Utils/Constant";
import { CustomInputFields } from "../../CustomComponent/CustomInputFields/CustomInputField";

export const levelOption = Array.from({ length: 50 }, (_, i) => ({
  value: `value${i + 1}`,
  name: (i + 1).toString(),
}));

function TimeMessage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectLevel, setSelectLevel] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [levelId, setLevelId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [list, setList] = useState([]);
  const [messageTypeId, setMessageTypeID] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [optionList, setOptionList] = useState();
  const [msgTitle, setMsgTitle] = useState([]);

  const [selectedLevelStatus, setSelectedLevelStatus] = useState();
  const [showModalupdate, setShowModalupdate] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowModalupdate(false);
    reset();
  };
  const handleShowUpdateModal = () => setShowModalupdate(true);

  const [showModal1, setShowModal1] = useState(false);
  const handleCloseModal2 = () => setShowModal1(false);
  const handleShowModal2 = () => setShowModal1(true);

  const [showStatus, setShowStatus] = useState(false);
  const handleCloseStatus = () => setShowStatus(false);
  const handleShowStatus = () => setShowStatus(true);

  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let levelOption = [];
    list.map((item) => {
      if (item.is_active == ACTIVE_TYPE.IS_ACTIVE) {
        levelOption.push({ id: item.id, name: item.level });
      }
    });
    setOptionList(levelOption);
  }, [list]);

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };

  const handleShowModal = () => {
    setIsEdit(false);
    reset();
    setShowModal(true);
  };
  {
    /*=============Add-message=========*/
  }
  const onSubmit = (data) => {
    const PostData = new FormData();
    PostData.append("message_type", MESSAGE_TYPE.TIME_MESSAGE);
    PostData.append("title", data.timeMessage);
    PostData.append("level", data.level);
    setIsLoading(true);
    IndependicareApi.timeMessage(PostData, token).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("TIME_MESSAGE_ADDED_SUCCESSFULLY"), "success");
          messageGetData(MESSAGE_TYPE.TIME_MESSAGE);
          handelGetData();
          setShowModal(false);
          reset();
          setIsLoading(false);
        } else if (
          response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(t("VALIDATIONS_ERROR"), "error");
          setIsLoading(false);
          setShowModal(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsLoading(false);
        }
      }
    });
  };
  useEffect(() => {
    handelGetData();
    messageGetData(MESSAGE_TYPE.TIME_MESSAGE);
  }, []);

  const handelGetData = () => {
    setIsLoading(true);
    IndependicareApi.fetchLevel(token).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        setList(response?.data?.level);
        setIsLoading(false);
      }
    });
  };

  const messageGetData = (messageType) => {
    setIsLoading(true);
    IndependicareApi.fetchMessage(token, messageType).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        setMsgTitle(response?.data?.message_type);
        setIsLoading(false);
      }
    });
  };

  const onChangeHandler = (e) => {
    setLevelId(e.target.value);
  };

  const updateSubmit = (formData) => {
    const PostData = new FormData();
    PostData.append("message_type", MESSAGE_TYPE.TIME_MESSAGE);
    PostData.append("title", formData.timeMessage);
    PostData.append("level", formData.level);
    setIsLoading(true);
    IndependicareApi.updateTimeMessage(PostData, token, messageTypeId).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data.code];
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("TIME_MESSAGE_UPDATE_SUCCESSFULLY"), "success");
          messageGetData(MESSAGE_TYPE.TIME_MESSAGE);
          handelGetData();
          setShowModalupdate(false);
          reset();
        } else if (
          response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  useEffect(() => {
    if (isEdit && messageTypeId) {
      IndependicareApi.detailMessage(token, messageTypeId).then((response) => {
        setValue("timeMessage", response?.data[0]?.title);
        setValue("level", response?.data[0]?.level_id);
      });
    } else {
      setValue("timeMessage", "");
      setValue("level", "");
      clearErrors("timeMessage", "");
      clearErrors("level", "");
    }
  }, [isEdit, messageTypeId, showModalupdate]);

  {
    /*=========Delete Relation ============*/
  }
  const Messagedelete = (messageTypeId) => {
    setIsLoading(true);
    IndependicareApi.deleteMessage(token, messageTypeId).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data.code];

      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("TIME_MESSAGE_DELETED_SUCCESSFULLY"), "success");
        handelGetData();
        messageGetData(MESSAGE_TYPE.TIME_MESSAGE);
        handleCloseModal2(false);
      } else if (
        response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
        response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
      ) {
        Toster(response?.code?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      handleCloseModal2(false);
    });
  };

  {
    /*=========Time-message-status ===============*/
  }

  const StatusTimeMessage = (messageTypeId) => {
    setIsLoading(true);
    IndependicareApi.TimeMessageStatus(token, messageTypeId).then(
      (response) => {
        handelGetData();
        messageGetData(MESSAGE_TYPE.TIME_MESSAGE);
        setIsLoading(false);
        handleCloseStatus();
      }
    );
  };

  const filteredList = msgTitle.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Container>
          <Row className="align-items-center">
            <Col className="setSearchInputField">
              <CustomSearchField setSearchQuery={setSearchQuery} />
            </Col>
            <Col>
              <div className="modelButtonPropwerty">
                <Button
                  className="modalTextButton"
                  type="button"
                  onClick={handleShowModal}
                >
                  {t("ADD_NEW")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <div className={styles.eventTable}>
            <Table className={styles.customTable} striped>
              <thead className={styles.tableHeadProperty}>
                <tr>
                  <th className={styles.EventalignLeft}>{t("TIME_MESSAGE")}</th>
                  <th className={styles.EventalignLeft}>{t("LEVEL")}</th>
                  <th className={`${styles.eventAction1} ${styles.alignRight}`}>
                    {t("STATUS")}
                  </th>
                  <th className={`${styles.eventAction} ${styles.alignRight}`}>
                    {t("ACTION")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredList &&
                  filteredList.length > 0 &&
                  filteredList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.alignLeft}>{item.title}</td>
                        <td
                          className={`${styles.alignLeft} ${styles.alignMiddleText}`}
                        >
                          {item.level}
                        </td>
                        <td className={styles.alignRight}>
                          {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td className={styles.alignRight}>
                          <Dropdown className={styles.setEventDropdown}>
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleShowUpdateModal();
                                  setMessageTypeID(item.id);
                                  setIsEdit(true);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("EDIT")}</span>
                                  <Image src={edit} className="dropdownIcons" />
                                </Dropdown.Item>
                              </div>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  setMessageTypeID(item.id);
                                  handleShowModal2();
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("DELETE")}</span>
                                  <DeleteOutlineIcon className="iconColor" />
                                </Dropdown.Item>
                              </div>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleShowStatus();
                                  setMessageTypeID(item.id);
                                  setSelectedLevelStatus(item.is_active);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>
                                    {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                                      ? "Inactive"
                                      : "Active"}
                                  </span>
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Container>
        <div className={styles.noRecord}>
          <p>
            {!isLoading && filteredList.length == 0 ? t("NO_RECORD_FOUND") : ""}
          </p>
        </div>
      </div>
      {/*=========Add-Message==============*/}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("TIME_MESSAGE")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomInputFields
              type="text"
              PlaceHolder={t("ENTER_TIME_MESSAGE")}
              RegisterName="timeMessage"
              register={register}
              formState={formState}
              label={t("TIME_MESSAGE")}
              setValue={setValue}
            />

            <CustomSelectFields
              PlaceHolder={t("SELECT_LEVEL")}
              RegisterName="level"
              register={register}
              options={optionList}
              formState={formState}
              label={t("LEVEL")}
              onChangeHandler={onChangeHandler}
            />
            <Button type="submit" className="AddSiteSubmitButton">
              {t("DONE")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/*=========update-message-Modal==============*/}
      <Modal show={showModalupdate} onHide={handleCloseUpdateModal}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("TIME_MESSAGE")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseUpdateModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(updateSubmit)}>
            <CustomInputFields
              type="text"
              PlaceHolder={t("ENTER_TITLE")}
              RegisterName="timeMessage"
              register={register}
              formState={formState}
              label={t("TITLE")}
              setValue={setValue}
            />

            <CustomSelectFields
              PlaceHolder={t("SELECT_LEVEL")}
              RegisterName="level"
              register={register}
              options={optionList}
              formState={formState}
              label={t("LEVEL")}
              onChangeHandler={onChangeHandler}
            />
            <Button type="submit" className="AddSiteSubmitButton">
              {t("UPDATE")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/*=========delete Meassage-Modal==============*/}
      <Modal show={showModal1} onHide={handleCloseModal2}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal2}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TIME_MESSAGE")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              Messagedelete(messageTypeId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      {/*=========Status-Modal==============*/}
      <Modal show={showStatus} onHide={handleCloseStatus}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseStatus}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            {selectedLevelStatus === ACTIVE_TYPE.IS_ACTIVE ? (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_TIME_MESSAGE")}</p>
            ) : (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_TIME_MESSAGE")}</p>
            )}
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              StatusTimeMessage(messageTypeId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TimeMessage;
