import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import styles from "./AddPerson.module.css";
import {
  CustomAudioInputField,
  CustomAudioPromptInput,
  CustomInputFieldsModelForm,
  CustomSelectFieldsModelForm,
  CustomTextareaFields,
} from "../CustomComponent/CustomInputFields/CustomInputField";
import { Image } from "react-bootstrap";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import IndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import getPersonList from "../../Components/AddPerson/ShowPerson";
import { clearUserState } from "../../redux/slices/userSlice";
import Toster from "../../Toster/Toster";
import { Navigate } from "react-router-dom";
import cameraIcon from "../../../src/Assests/Images/cameraIcon.png";
import defaultImage from "../../Assests/Images/avtaarImage.jpg";
import PlayAudioBtn from "../../Assests/Images/PlayAudioBtn.png";
import ReactAudioPlayer from "react-audio-player";
import Loader from "../LoaderComponent/LoaderComponent";
// import defaultAudio from "../../Assests/Audio/defaultAudio.mp3";

const AddPerson = ({
  showModal,
  setShowModal,
  handleCloseModal,
  getPersonList,
  personId,
  isEdit,
  detailAudio,
  isLoading,
  setIsLoading,
  pageNo,
  searchQuery,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    errors,
    control,
    className,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState(defaultImage);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [slectedLanguage, setSelectedLanguage] = useState("");
  const [languageOption, setLanguageOption] = useState([]);
  const [showInputField, setShowInputField] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [language, setLanguage] = useState([]);
  const [selectMobileDevice, setSelectMobileDevice] = useState("");
  const [audioFile, setAudioFile] = useState("");

  const [audioToneFile, setAudioToneFile] = useState("");
  const [currentAudio, setCurrentAudio] = useState("");
  const [currentAudioTone, setCurrentAudioTone] = useState("");

  const [audioPrompt, setAudioPrompt] = useState("");
  const [currentAudioPrompt, setCurrentAudioPrompt] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedprompt, setSelectedprompt] = useState("none");
  // const [isLoading, setIsLoading] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showAudioModal1, setShowAudioModal1] = useState(false);
  // const [isDefaultTone, setIsDefaultTone] = useState(true);
  // console.log(isDefaultTone, "isDefaultTone");

  const handleCloseAudioModal = () => setShowAudioModal(false);
  const handleCloseAudioModal1 = () => setShowAudioModal1(false);

  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  const playAudio = (type) => {
    if (type == "tone") {
      setCurrentAudio(currentAudioTone);
    }
    if (type == "promt") {
      setCurrentAudio(currentAudioPrompt);
    }
    setShowAudioModal(true);
  };

  const playAudio1 = () => {
    setShowAudioModal1(true);
  };

  const options = [
    { id: "option1", name: "None", show: false, value: "none" },
    { id: "option2", name: "TTS-Prompt", show: false, value: "tts-prompt" },
    { id: "option3", name: "Audio-Prompt", show: false, value: "audio-prompt" },
  ];

  const [allOptions, setAllOptions] = useState(options);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    const updatedOptions = allOptions.map((option) => ({
      ...option,
      show: option.value === selectedValue && selectedValue !== "none",
    }));

    if (selectedValue == "none") {
      clearErrors("audio_prompt");
    }

    setAllOptions(updatedOptions);
    setSelectedprompt(selectedValue);
  };

  const validateAudioFile = (file) => {
    const allowedTypes = [
      "audio/mpeg", // .mp3
      "audio/wav", // .wav
      "audio/aac", // .aac
      "audio/flac", // .flac
      "audio/ogg", // .ogg
      "audio/x-ms-wma", // .wma
      "audio/aiff", // .aiff
      "audio/alac", // .alac
      "audio/mp4", // .m4a
      "audio/amr", // .amr
    ];

    const maxFileSize = 25 * 1024 * 1024; // 25 MB in bytes

    if (!file) {
      // return t("Audio file is required");
      return true;
    }

    // if (file && !allowedTypes.includes(file.type)) {
    //   return t("UNSUPPORTED_FILE_TYPE_PLEASE_UPLOAD_A_VALID_AUDIO_FILE");
    // }

    if (file && file.size > maxFileSize) {
      return t("FILE_TOO_LARGE_PLEASE_UPLOAD_A_FILE_SMALLER_THAN_25MB");
    }

    return true;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      setAudioFile(file);
      setCurrentAudio(fileURL);
      setCurrentAudioPrompt(fileURL);
    }
    setValue("promptIntroAudio", file, { shouldValidate: true });
    setErrorMessage("");
  };

  const handleFileChangeTone = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      setAudioToneFile(file);
      setCurrentAudio(fileURL);
      setCurrentAudioTone(fileURL);
    }
    setValue("AlertToneAudio", file, { shouldValidate: true });
    setErrorMessage("");
    // setIsDefaultTone(false);
  };

  const handleFileChangeAudioPrompt = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      setAudioPrompt(file);
      setCurrentAudioPrompt(fileURL);
      setValue("audio_prompt", file, { shouldValidate: true });
    }
    setValue("audio_prompt", file);
    setErrorMessage("");
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Validate file type
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
    ];
    if (file && !validImageTypes.includes(file.type)) {
      setErrorMessage(t("PLEASE_UPLOAD_A_VALID_IMAGE_FILE"));
      return;
    }

    // Validate file size (example: max 5MB)
    const maxSizeInBytes = 10 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes) {
      setErrorMessage(t("IMAGE_SIZE_SHOULD_NOT_EXCEED_10MB"));
      return;
    }

    // Clear previous error messages
    setErrorMessage("");

    // Proceed with uploading the image
    setSelectedImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };

  const languageType = [
    { id: "english", value: "english", name: "English" },
    { id: "spanish", value: "spanish", name: "Spanish" },
    { id: "french", value: "french", name: "French" },
    { id: "hindi", value: "hindi", name: "Hindi" },
  ];

  const handleSelectChangeDevice = (event) => {
    const selectedValue = event.target.value;
    setLanguage(selectedValue);
    setValue("language", selectedValue); // Update the form state with the selected value
    clearErrors("language"); // Clear any validation errors for the "language" field
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const UpdateUrl = `stakeholder/v1/update-person/${personId}`;
    const AddUrl = "stakeholder/v1/save-person";
    let url;
    let method;
    if (personId) {
      url = UpdateUrl;
      method = "put";
    } else {
      url = AddUrl;
      method = "post";
    }

    if (!data.personName) {
      return;
    }
    const formData = new FormData();
    if (selectedImageFile) {
      formData.append("profile_img", selectedImageFile);
    }
    formData.append("person_name", data.personName);
    formData.append("alert_tone_audio", audioToneFile);
    formData.append("prompt_intro_text", data.promptIntroText);
    formData.append("prompt_intro_audio", audioFile);
    formData.append("language", data.language);

    if (selectedprompt == "audio-prompt") {
      formData.append("audio_prompt", audioPrompt);
    }

    if (selectedprompt == "tts-prompt") {
      formData.append("tts_prompt", data.tts_prompt);
    }
    formData.append("prompt_option", selectedprompt);

    formData.append("notes", data.notes);
    IndependicareApi.addPerson(formData, token, url, method).then(
      (response) => {
        const ResponseCode = STATUS_MSG[response?.data.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("session expired"), "error");
          Navigate("/signin");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            if (personId) {
              Toster(t("PERSON_UPDATED_SUCCESSFULLY"), "success");
            } else {
              Toster(t("PERSON_ADDED_SUCCESSFULLY"), "success");
              reset();
            }
            getPersonList(pageNo, searchQuery);
            handleCloseModal();
            setIsLoading(false);
          } else if (
            response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
          ) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
          setIsLoading(false);
        }
      }
    );
  };
  useEffect(() => {
    if (isEdit && personId) {
      IndependicareApi.detailPersonForEdit(token, personId).then((response) => {
        setValue("personName", response?.data?.person_name);
        setValue("promptIntroText", response?.data?.prompt_intro_text);
        setValue("AlertToneAudio", response?.data?.alert_tone_audio);
        setValue("language", response?.data?.language);
        setValue("prompt_option", response?.data?.prompt_option);
        setValue("notes", response?.data?.notes);

        setValue("tts_prompt", response?.data?.tts_prompt);
        setValue("promptIntroAudio", response?.data?.prompt_intro_audio);
        setValue("audio_prompt", response?.data?.audio_prompt);
        setAudioToneFile(response?.data?.alert_tone_audio);
        if (response?.data?.alert_tone_audio) {
          setCurrentAudioTone(response?.data?.alert_tone_audio);
        }

        setAudioFile(response?.data?.prompt_intro_audio);
        if (response?.data?.prompt_intro_audio) {
          setCurrentAudioPrompt(response?.data?.prompt_intro_audio);
        }
        setImageUrl(response?.data?.profile_img || defaultImage);
        // setAudioFile(response?.data?.prompt_intro_audio);

        setAudioPrompt(response?.data?.audio_prompt);

        // if (response?.data?.audio_prompt?.includes("null")) {
        //   setCurrentAudioPrompt(null);
        // } else {
        //   setCurrentAudioPrompt(response?.data?.audio_prompt);
        // }

        if (response?.data?.prompt_intro_audio?.includes("null")) {
          setCurrentAudio("");
          setCurrentAudioPrompt("");
          setAudioFile("");
        } else {
          setCurrentAudio(response?.data?.prompt_intro_audio);
        }

        setSelectedprompt(response?.data?.prompt_option);

        const updatedOptions = allOptions.map((option) => ({
          ...option,
          show:
            option.value === response?.data?.prompt_option &&
            response?.data?.prompt_option !== "none",
        }));
        setAllOptions(updatedOptions);
      });
    } else {
      setValue("personName", "");
      clearErrors("personName", "");
      setValue("promptIntroText", "");
      clearErrors("promptIntroText", "");
      setValue("promptIntroAudio", "");
      clearErrors("promptIntroAudio", "");
      setValue("language", "");
      clearErrors("language", "");
      setValue("prompt_option", "");
      clearErrors("prompt_option", "");
      setValue("notes", "");
      clearErrors("notes", "");
      setImageUrl(defaultImage);
      setAudioFile("");
      setValue("tts_prompt", "");
      clearErrors("tts_prompt", "");
      setAudioPrompt("");
      clearErrors("tts_prompt", "");
      setSelectedprompt("none");
      // setAudioPromptPrev(null);
      setCurrentAudio("");
      setCurrentAudioPrompt("");
      setAllOptions(options);
      setErrorMessage(null);
      setValue("AlertToneAudio", "");
      setAudioToneFile("");
      clearErrors("AlertToneAudio", "");
    }
  }, [isEdit, personId, showModal]);

  const onClose = () => {
    handleCloseModal();
    setCurrentAudio("");
    setCurrentAudioTone("");
    setAudioFile("");
    setAudioPrompt("");
    setCurrentAudioPrompt("");
    setAudioToneFile("");
    // setIsDefaultTone(true);
  };

  // const handleToggleTone = (e) => {
  //   const value = e.target.checked;
  //   console.log(value, "value");
  //   setIsDefaultTone(value);
  //   if (value) {
  //     setAudioToneFile("");
  //   }
  // };

  // const playDefaultAudio = () => {
  //   setCurrentAudio(defaultAudio);
  //   setShowAudioModal(true);
  // };

  return (
    <div>
      {isLoading && <Loader />}
      <Modal show={showModal} onHide={onClose}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className="setModelTitleProperty">
            {isEdit ? t("UPDATE_PERSON") : t("ADD_PERSON")}
          </Modal.Title>
          <CancelIcon className="closeIconProperty" onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader />}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h4 className={styles.headingTextProfile}>
              {t("PROFILE_PICTURE")}
            </h4>

            <div className={styles.uploadimageContainer}>
              <div className={styles.profileContainer}>
                <Image
                  src={imageUrl || "default-profile-img-url"}
                  roundedCircle
                  className={styles.imageUrlProperty}
                />

                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .gif, .png, .webp"
                  onChange={handleImageUpload}
                />
                <div className={styles.setButton} onClick={handleIconClick}>
                  <Image src={cameraIcon}></Image>
                </div>

                {/* Display error message if any */}
              </div>
              {errorMessage && (
                <p className={styles.errorMessage}>{errorMessage}</p>
              )}
            </div>
            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_PERSON_NAME")}
              RegisterName="personName"
              register={register}
              formState={formState}
              label={t("PERSON_NAME")}
              className={styles.addDeviceProperty}
              onChange={(e) => {
                const inputValue = e.target.value;

                // Remove space if it's the first character
                if (inputValue.startsWith(" ")) {
                  setValue("personName", inputValue.trimStart(), {
                    shouldValidate: true,
                  });
                } else {
                  setValue("personName", inputValue, {
                    shouldValidate: true,
                  });
                }
              }}
            />
            {/* <div className={styles.defaultToneSection}>
              <div className={styles.defaultTone}>Default alert tone</div>
              <div>
                <Form.Check
                  type="switch"
                  className="tone-switch"
                  checked={isDefaultTone}
                  onChange={(e) => handleToggleTone(e)}
                />
              </div>
            </div> */}

            <CustomAudioInputField
              type="file"
              PlaceHolder={t("UPLOAD_ALERT_TONE_AUDIO")}
              label={t("ALERT_TONE_AUDIO")}
              RegisterName="AlertToneAudio"
              // register={register}
              formState={formState}
              onChange={handleFileChangeTone}
              value={audioToneFile}
              className={`${styles.addDeviceProperty} ${styles.setTextPlace}`}
              errorMessage={errorMessage}
              // register={{
              //   ...register("promptIntroAudio", {
              //     required: "Audio file is required",
              //   }),
              // }}
              register={{
                ...register("AlertToneAudio", {
                  // required: "Audio file is required",
                  validate: !isEdit ? validateAudioFile : false,
                }),
              }}
            />
            {audioToneFile ? (
              <p className={styles.audioFile}>
                <Image
                  src={PlayAudioBtn}
                  style={{ cursor: "pointer" }}
                  onClick={() => playAudio("tone")}
                />
              </p>
            ) : null}
            {/* {isDefaultTone ? (
              <p className={styles.audioFile}>
                <Image
                  src={PlayAudioBtn}
                  style={{ cursor: "pointer" }}
                  onClick={() => playDefaultAudio()}
                />
              </p>
            ) : null} */}

            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_PROMPT_TEXT_TO_SPEECH")}
              RegisterName="promptIntroText"
              register={register}
              formState={formState}
              label={t("PROMPT_INTRO_TEXT_TO_SPEECH")}
              className={styles.addDeviceProperty}
              // onChange={(e) => {
              //   setValue(
              //     "promptIntroText",
              //     e.target.value.replace(/\s/g, " "),
              //     {
              //       shouldValidate: true,
              //     }
              //   );
              // }}
              onChange={(e) => {
                const inputValue = e.target.value;

                // Remove space if it's the first character
                if (inputValue.startsWith(" ")) {
                  setValue("promptIntroText", inputValue.trimStart(), {
                    shouldValidate: true,
                  });
                } else {
                  setValue("promptIntroText", inputValue, {
                    shouldValidate: true,
                  });
                }
              }}
            />

            <CustomAudioInputField
              type="file"
              PlaceHolder={t("UPLOAD_PROMPT_INTRO_AUDIO")}
              label={t("PROMPT_INTRO_AUDIO")}
              RegisterName="promptIntroAudio"
              // register={register}
              formState={formState}
              onChange={handleFileChange}
              value={audioFile}
              className={`${styles.addDeviceProperty} ${styles.setTextPlace}`}
              errorMessage={errorMessage}
              // register={{
              //   ...register("promptIntroAudio", {
              //     required: "Audio file is required",
              //   }),
              // }}
              register={{
                ...register("promptIntroAudio", {
                  // required: "Audio file is required",
                  validate: !isEdit ? validateAudioFile : false,
                }),
              }}
            />
            {audioFile ? (
              <p className={styles.audioFile}>
                <Image
                  src={PlayAudioBtn}
                  style={{ cursor: "pointer" }}
                  onClick={() => playAudio("promt")}
                />
              </p>
            ) : null}

            <CustomSelectFieldsModelForm
              PlaceHolder={t("SELECT_LANGUAGE")}
              RegisterName="language"
              register={register}
              options={languageType}
              formState={formState}
              label={t("LANGUAGE_TO_USE")}
              value={selectMobileDevice}
              onChange={handleSelectChangeDevice}
              className={`${styles.addDeviceProperty} ${styles.langSelect}`}
            />
            <div>
              <p className={styles.promptText}>Prompt options</p>
              {allOptions.map((option) => (
                <React.Fragment key={option.id}>
                  <div className={styles.radioCheck}>
                    <label>
                      <span>{option.name}</span>
                      <input
                        className={styles.radioPromptOption}
                        type="radio"
                        name="option"
                        value={option.value}
                        checked={selectedprompt === option.value}
                        onChange={handleOptionChange}
                      />
                    </label>
                  </div>
                  {/* <div>
                    {option.show ? (
                      option.value === "tts-prompt" ? (
                        <CustomInputFieldsModelForm
                          type="text"
                          PlaceHolder={t("ENTER_TTS_PROMPT")}
                          RegisterName="tts_prompt"
                          register={register}
                          formState={formState}
                          className={styles.addDeviceProperty}
                          // onChange={(e) => {
                          //   setValue(
                          //     "tts_prompt",
                          //     e.target.value.replace(/\s/g, " "),
                          //     {
                          //       shouldValidate: true,
                          //     }
                          //   );
                          // }}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            // Remove space if it's the first character
                            if (inputValue.startsWith(" ")) {
                              setValue("tts_prompt", inputValue.trimStart(), {
                                shouldValidate: true,
                              });
                            } else {
                              setValue("tts_prompt", inputValue, {
                                shouldValidate: true,
                              });
                            }
                          }}
                        />
                      ) : option.value === "audio-prompt" ? (
                        <>
                          <CustomAudioPromptInput
                            type="file"
                            PlaceHolder={t("UPLOAD_ALERT_TONE_AUDIO")}
                            RegisterName="audio_prompt"
                            // register={register}
                            formState={formState}
                            onChange={handleFileChangeAudioPrompt}
                            value={audioPrompt}
                            className={`${styles.addDeviceProperty} ${styles.setTextPlace}`}
                            errorMessage={errorMessage}
                            register={{
                              ...register("audio_prompt", {
                                // required: "Audio file is required",
                                validate: !isEdit ? validateAudioFile : false,
                              }),
                            }}
                          />

                          {currentAudioPrompt ? (
                            <div>
                              <p>
                                <Image
                                  src={PlayAudioBtn}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => playAudio1()}
                                />
                              </p>
                            </div>
                          ) : null}
                        </>
                      ) : null
                    ) : null}
                  </div> */}
                </React.Fragment>
              ))}
            </div>

            <CustomTextareaFields
              label="Notes"
              PlaceHolder="Enter..."
              RegisterName="notes"
              register={register}
              formState={formState}
              // onChange={(e) => {
              //   setValue("notes", e.target.value.replace(/\s/g, " "), {
              //     shouldValidate: true,
              //   });
              // }}
              onChange={(e) => {
                const inputValue = e.target.value;

                // Remove space if it's the first character
                if (inputValue.startsWith(" ")) {
                  setValue("notes", inputValue.trimStart(), {
                    shouldValidate: true,
                  });
                } else {
                  setValue("notes", inputValue, {
                    shouldValidate: true,
                  });
                }
              }}
            />
            <Button className="AddSiteSubmitButton" type="submit">
              {isEdit ? t("UPDATE_PERSON") : t("ADD_PERSON")}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>{" "}
      {/*=========Prompt-intro-TTS-Modal==============*/}
      <Modal show={showAudioModal} onHide={handleCloseAudioModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className={styles.audioIntro}>
              <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAudio && (
            <ReactAudioPlayer
              src={currentAudio}
              autoPlay
              controls
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
      </Modal>
      {/*=========Audio-Prompt-Modal==============*/}
      {/* <Modal show={showAudioModal1} onHide={handleCloseAudioModal1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className={styles.audioIntro}>
              <h4>{t("AUDIO_PROMPT")}</h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAudioPrompt && (
            <ReactAudioPlayer
              src={currentAudioPrompt}
              autoPlay
              controls
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
      </Modal> */}
    </div>
  );
};
export default AddPerson;
