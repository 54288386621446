import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import {
  CustomMultiSelectFields,
  CustomSelectFields,
} from "../CustomComponent/CustomInputFields/CustomInputField";
import DutyHoursComp from "./DutyHoursComp";
import { CustomSelectAssignPerson } from "../CustomComponent/CustomInputFields/CustomInputField";
import { Modal } from "react-bootstrap";
import styles from "./CareGiver.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../CustomComponent/CustomSearchField/CustomSearchField";
import debounce from "debounce";
import Loader from "../LoaderComponent/LoaderComponent";

function AssignSenior({
  personOption,
  listInnerRef,
  searchQuery,
  setSearchQuery,
  debouncedSearchPerson,
  handleScroll,
  handleShowPerson,
  handleClosePerson,
  selectedPersonName,
  selectedPersonId,
  setSelectedPersonName,
  setSelectedPersonId,
  showPerson,
  isLoading,

  relationList,
  permissionList,
  levelList,
  selectRelation,
  setSelectRelation,
  // selectPerson,
  // setSelectPerson,
  selectLevel,
  setSelectLevel,
  selectPermission,
  setSelectPermission,
  dutyHoursData,
  setDutyHoursData,
  addedCaregiverId,
  addedCaregiverList,
}) {
  const { t } = useTranslation();
  const { register, formState } = useForm({
    mode: "onChange",
  });

  const handleSelectChangeRelation = (event) => {
    setSelectRelation(event.target.value);
  };
  const handleSelectChangeLevel = (event) => {
    setSelectLevel(event.target.value);
  };
  // const handleSelectChangePerson = (event) => {
  //   setSelectPerson(event.target.value);
  // };
  const handleSelectChangePermission = (value) => {
    setSelectPermission(value);
  };

  const handlePersonSelect = (option) => {
    // if (selectedPersonId === option.id) {
    //   // Deselect if already selected
    //   setSelectedPersonId(null);
    //   setSelectedPersonName("");
    // } else {
    //   // Select new person
    setSelectedPersonId(option.id);
    setSelectedPersonName(option.person_name);
    // }
    handleClosePerson(); // Close the modal after selection
  };

  return (
    <div>
      <Form>
        {/* <CustomSelectAssignPerson
          PlaceHolder={t("SELECT_SENIOR_PERSON")}
          RegisterName="person"tSelectedPerso
          options={personList}
          // options={personList.filter(
          //   (person) =>
          //     !addedCaregiverList.some((item) =>
          //       item.person_names.includes(person.name)
          //     )
          // )}
          register={register}
          formState={formState}
          label={t("SENIOR_PERSON")}
          value={selectPerson}
          onChangeHandler={handleSelectChangePerson}
          className="selectIcon"
        /> */}
        <div className={`border-none ${styles.personField}`}>
          <Form.Group className="mb-3">
            <Form.Label className={styles.personLabel}>
              {t("PERSON")}
            </Form.Label>
            <Form.Control
              type="text"
              value={selectedPersonName}
              placeholder={t("SELECT_PERSON")}
              onClick={handleShowPerson}
              readOnly
              className={`${styles.addPersonProperty} ${styles.inputStyle}`}
            />
          </Form.Group>
        </div>
        <Modal show={showPerson} onHide={handleClosePerson}>
          <Modal.Header className="d-flex justify-content-between">
            <Modal.Title className="setModelTitleProperty">
              {t("SELECT_OPTIONS")}
            </Modal.Title>{" "}
            <CancelIcon
              className="closeIconProperty"
              onClick={handleClosePerson}
            />
          </Modal.Header>
          <Modal.Body>
            {isLoading && <Loader />}
            <Form.Group>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <CustomSearchField
                    setSearchQuery={(val) => {
                      setSearchQuery(val);
                      debouncedSearchPerson(val);
                    }}
                  />
                </div>
                <div
                  className={styles.personListContainer}
                  ref={listInnerRef}
                  onScroll={debounce(handleScroll, 100)}
                >
                  {personOption && personOption.length > 0 ? (
                    personOption.map((option) => (
                      <div
                        key={option.id}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <Form.Label htmlFor={`person-${option.id}`}>
                          {option.person_name}
                        </Form.Label>
                        <Form.Check
                          key={option.value}
                          type="checkbox"
                          id={`person-${option.id}`}
                          value={option.id}
                          checked={selectedPersonId === option.id} // Correctly set the checked attribute
                          onChange={() => handlePersonSelect(option)} // Call the selection handler
                        />
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center p-2">
                      <p>{t("NO_RECORD_FOUND")}</p>
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
        </Modal>

        <CustomSelectAssignPerson
          PlaceHolder={t("SELECT_RELATION")}
          RegisterName="relation"
          register={register}
          options={relationList}
          formState={formState}
          label={t("RELATION")}
          value={selectRelation}
          onChangeHandler={handleSelectChangeRelation}
        />

        <CustomMultiSelectFields
          PlaceHolder={t("SELECT_PERMISSION")}
          RegisterName="permission"
          register={register}
          options={permissionList}
          formState={formState}
          label={t("PERMISSION")}
          value={selectPermission}
          onChangeHandler={handleSelectChangePermission}
        />
        <CustomSelectAssignPerson
          PlaceHolder={t("SELECT_LEVEL")}
          RegisterName="level"
          options={levelList}
          register={register}
          formState={formState}
          label={t("LEVEL")}
          value={selectLevel}
          onChangeHandler={handleSelectChangeLevel}
          className="selectIcon"
        />
        <DutyHoursComp
          onDutyHoursChange={setDutyHoursData}
          initialDutyHours={dutyHoursData}
        />
      </Form>
    </div>
  );
}
export default AssignSenior;
