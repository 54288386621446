import React from "react";

const Pagination = ({ count, pageLimit, currentPage, onPageChange }) => {
  if (count <= 10) return null; // Don't show pagination if count is less than or equal to 10

  const totalPages = Math.ceil(count / pageLimit);

  const handleClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const getPageNumbers = () => {
    const pages = [];

    if (totalPages <= 4) {
      // Show all pages if total pages are 4 or less
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else if (currentPage <= 2) {
      // Show first 4 pages if current page is 1 or 2
      pages.push(1, 2, 3, 4);
    } else if (currentPage > totalPages - 2) {
      // Show last 4 pages if current page is one of the last two
      pages.push(totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
    } else {
      // Show the current page, one before and two after
      pages.push(
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2
      );
    }

    return pages;
  };

  return (
    <nav aria-label="Page navigation">
      <ul
        style={{
          justifyContent: "end",
          display: "flex",
          padding: "0",
          listStyle: "none",
          margin: "0", // Ensure no default margin
          marginRight: 20,
        }}
        className="pagination"
      >
        {getPageNumbers().map((pageNumber, index) => (
          <li
            key={index}
            className={`page-item ${
              currentPage === pageNumber ? "active" : ""
            }`}
          >
            <button
              className="shadow-none page-link"
              style={{
                color: currentPage === pageNumber ? "white" : "#1D4488",
                backgroundColor:
                  currentPage === pageNumber ? "#1D4488" : "white",
                border: "1px solid #1D4488",
                padding: "6px 12px",
                zIndex: 0,
                borderRadius:
                  index === 0
                    ? "5px 0 0 5px" // Rounded left corners for the first box
                    : index === getPageNumbers().length - 1
                    ? "0 5px 5px 0" // Rounded right corners for the last box
                    : "0", // No border-radius for middle boxes
                cursor: "pointer",
                marginLeft: index !== 0 ? "-1px" : "0", // Overlap borders to remove gaps
              }}
              onClick={() => handleClick(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
