import React, { useState, useEffect } from "react";
import { fetchRequestPermission } from "./firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-bootstrap/Toast";
// import { getRouteFromNotification } from "./helper";

const FireBaseNotification = () => {
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [status, setStatus] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationAction, setNotificationAction] = useState();

  function ToastDisplay() {
    return (
      <Toast
        show={showNotification}
        // onClick={() => {
        //   navigate(getRouteFromNotification(notificationAction));
        // }}
        // onClick={() => {
        //   setShowNotification(false);
        // }}
        onClose={() => setShowNotification(false)}
        className="notify_toast_firebase"
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">{notification?.title}</strong>
        </Toast.Header>
        <Toast.Body>
          {notification?.body?.split(" ").slice(0, 16).join(" ")}
        </Toast.Body>
      </Toast>
    );
  }

  useEffect(() => {
    if (status == true) {
      toggleShowNotification();
    }
  }, [notification]);

  useEffect(() => {
    fetchRequestPermission();

    navigator.serviceWorker.addEventListener("message", (event) => {
      const { data } = event;
      setNotificationAction(data.data.action);
      setStatus(true);
      setNotification({
        title: data.notification.title,
        body: data.notification.body,
      });
    });
  }, []);

  const toggleShowNotification = () => {
    setShowNotification(true); // Show the toast
    setTimeout(() => setShowNotification(false), 3000); // Automatically hide the toast after 3 seconds
  };

  return <>{ToastDisplay()}</>;
};

export default FireBaseNotification;
