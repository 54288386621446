import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Logo from "../../Components/Logo/Logo";
import style from "./SignupPage.module.css";
import { useTranslation } from "react-i18next";
import { CustomInputFields } from "../../Components/CustomComponent/CustomInputFields/CustomInputField";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { CustomPasswordInputField } from "../../Components/CustomComponent/CustomInputFields/CustomInputField";
import { CustomSelectFields } from "../../Components/CustomComponent/CustomInputFields/CustomInputField";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../CustomComponent/CustomButton/CustomButton";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import Loader from "../LoaderComponent/LoaderComponent";
import { useDispatch, useSelector } from "react-redux";
import caregiverCheckImg from "../../Assests/Images/caregiverCheckImg.svg";
import stakeholderCheckImg from "../../Assests/Images/stakeholderCheckImg.svg";
import caregiverUncheckImg from "../../Assests/Images/caregiverUncheckImg.svg";
import { setUserType } from "../../redux/slices/userSlice";
import { timeZoneCountryMapping } from "../../Utils/MockData";
import moment from "moment-timezone";
import { CONSTANT } from "../../Utils/Constant";
import stakeholderUncheckImg from "../../Assests/Images/stakeholderUncheckImg.svg";
import { CustomSelectFieldsSeachState } from "../CustomComponent/CustomInputFields/CustomInputField";
import { userSignUp } from "../../redux/slices/userSlice";
import { getCountryCallingCode, isValidPhoneNumber } from "libphonenumber-js";
// import { parsePhoneNumberFromString } from "libphonenumber-js";
// import {
//   parsePhoneNumberFromString,
//   getCountryCallingCode,
// } from "libphonenumber-js";
// import "react-phone-input-2/lib/style.css";

function UserSelectionPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dialCode, setDialCode] = useState();
  const [contactNumber, setContactNumber] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [countryID, setCountryID] = useState("");
  const [sourceID, setSourceID] = useState("");
  const [sourceData, setSourceData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [statesOption, setStatesOption] = useState([]);
  const [statesID, setStatesID] = useState("");
  const [ip, setIP] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const usertype = useSelector((state) => state.user.userType);

  const handleCountryData = () => {
    const countrydata = IndependicareApi.countryList();
    countrydata.then((res) => {
      setCountryData(res?.data?.country_list);
    });
  };

  useEffect(() => {
    handleCountryData();
  }, []);

  useEffect(() => {
    const options = [];
    if (statesData.length > 0) {
      statesData.map((state) => {
        options.push({ value: state.id, label: state.name });
      });
      setStatesOption(options);
    }
  }, [statesData]);

  const getSelectedCountryCode = (id) => {
    const selectedCountry = countryData?.find((val) => val?.id == id);
    if (selectedCountry?.iso_code) {
      setCountryCode(selectedCountry?.iso_code?.toLowerCase());
    }
  };

  const handleSelectChangeCountry = (e) => {
    setCountryID(e.target.value);
    getSelectedCountryCode(e.target.value);
  };

  const handleSelectChangeState = (e) => {
    setStatesID(e.value);
  };

  const handleStateData = (countryID) => {
    const statedata = IndependicareApi.stateList(countryID);
    statedata.then((res) => {
      const sortedStates = res?.data?.state_list.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setStatesData(sortedStates);
      // setStatesData(res?.data?.state_lis);
    });
  };

  // useEffect(() => {
  //   handleStateData(countryID);
  // }, [countryID]);
  useEffect(() => {
    if (countryID) {
      handleStateData(countryID);
    }
  }, [countryID]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredStates = statesData.filter((state) =>
    state.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // const usertype = localStorage.getItem("usertype");

  const handlesourceID = () => {
    const sourcedata = IndependicareApi.sourceID();
    sourcedata.then((res) => {
      setSourceData(res?.data?.caregiver_source_list);
    });
  };

  useEffect(() => {
    handlesourceID();
  }, [setCountryID]);

  const handleChangeSourceId = (e) => {
    setSourceID(e.target.value);
  };
  const initialUserType = localStorage.getItem("usertype") || "stakeholder";

  const [selectedOption, setSelectedOption] = useState(initialUserType);

  useEffect(() => {
    localStorage.setItem("usertype", selectedOption);
  }, [selectedOption]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    dispatch(setUserType(value));
  };

  //

  const onSubmit = (data) => {
    if (!isContactValid()) {
      return;
    }
    const formData = new FormData();
    formData.append("full_name", data.fullName);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("confirm_password", data.confirmPassword);
    formData.append("city", data.city);
    formData.append("zip", data.zipCode);
    formData.append("contact_number", contactNumber);
    formData.append("country_code", countryCode);
    formData.append("street_address", data.address);
    {
      data.address2 && formData.append("street_address_line2", data.address2);
    }
    formData.append("country_id", countryID);
    formData.append("state_id", statesID);
    // formData.append("dial_code", dialCode);
    formData.append("dial_code", `+${dialCode}`);
    formData.append("user_type", usertype);
    {
      usertype === "caregiver" && formData.append("source_id", sourceID);
    }

    // if (!contactNumber || contactNumber?.length > 4) {
    setIsLoading(true);
    dispatch(userSignUp(formData))
      // IndependicareApi.signUp(formData)
      .then((res) => {
        const response = res.payload;

        setIsLoading(false);
        const responseCode = STATUS_MSG[response?.data?.code];

        if (response?.code === STATUS_CODE.SUCCESS) {
          if (usertype === "stakeholder") {
            Toster(t("REGISTERED_SUCCESFULLY"), "success");
            navigate("/site");
          } else if (usertype === "caregiver") {
            Toster(t("REGISTERED_SUCCESFULLY"), "success");
            navigate("/stakeholder");
          }
          //   navigate("/site");
          //   Toster(t("REGISTERED_SUCCESFULLY"), "success");
          // } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          //   Toster(response?.data?.message, "error");
          // } else {
          //   Toster(t(responseCode), "error");
        } else if (
          response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code === STATUS_CODE.INVALID_TOKEN
        ) {
          Toster(t(responseCode), "error");
        } else {
          Toster(t(responseCode), "error");
        }
      })
      .catch((error) => {
        Toster(error, "error");
      });
    // }
  };

  useEffect(() => {
    const timeZone = moment.tz.guess();
    const countryCodeInfo = timeZoneCountryMapping[timeZone];
    // setDialCode(countryCodeInfo?.code);
    // setCountryCode(countryCodeInfo?.country);
  }, []);

  // for fetch current live country code
  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const countryCode = data?.country_code;
        if (countryCode) {
          setCountryCode(countryCode?.toLowerCase() || "us");
        }
      } catch (error) {
        console.error("Error fetching country code:", error);
      }
    };

    fetchCountryCode();
  }, []);

  const isContactValid = () => {
    if (!formState.isSubmitted) {
      return true;
    }
    const isValid =
      isValidPhoneNumber(`+${dialCode}${contactNumber}`) &&
      formState.isSubmitted;

    return isValid;
  };

  return (
    <div className={style.fullBackground}>
      {isLoading && <Loader />}
      <Container className={style.mAuto}>
        <div className={style.alignmentProperty}>
          <div className={style.logoSection}>
            <Logo />
          </div>
        </div>
        <div className={style.formFieldSection}>
          <div className={style.inputFieldSection}>
            <h3 className="headingContent">
              {t("WELCOME_TO")} {t("INDEPENDICARE")}
            </h3>
            <p className={style.paragraphText}>
              {t("FILL_OUT_THE_FORM_TO")} {usertype}
            </p>
            {/* ******* */}
            <div className="radio-with-Icon">
              <p className="radioOption-Item">
                <input
                  type="radio"
                  name="userType"
                  id="BannerType1"
                  value="stakeholder"
                  checked={selectedOption === CONSTANT.STAKEHOLDER_KEY}
                  onChange={handleChange}
                  // aria-invalid="false"
                />
                <label htmlFor="BannerType1">
                  <div>
                    <img
                      className={style.bottomSpace}
                      src={
                        selectedOption === CONSTANT.STAKEHOLDER_KEY
                          ? stakeholderCheckImg
                          : stakeholderUncheckImg
                      }
                      alt="First slide"
                    />
                  </div>
                  <div>
                    <h4>{t("STACKHOLDER")}</h4>
                  </div>
                </label>
              </p>

              <p className="radioOption-Item">
                <input
                  type="radio"
                  name="userType"
                  id="BannerType2"
                  value="caregiver"
                  checked={selectedOption === CONSTANT.CAREGIVER_KEY}
                  onChange={handleChange}
                  // aria-invalid="false"
                />
                <label htmlFor="BannerType2">
                  <div>
                    <img
                      className={style.bottomSpace}
                      src={
                        selectedOption === CONSTANT.CAREGIVER_KEY
                          ? caregiverCheckImg
                          : caregiverUncheckImg
                      }
                      alt="Second slide"
                    />
                  </div>
                  <div>
                    <h4>{t("CAREGIVER")}</h4>
                  </div>
                </label>
              </p>
            </div>
            {/* ******* */}
            <div className={style.registerFormsField}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <CustomInputFields
                  type="text"
                  PlaceHolder={t("ENTER_FULL_NAME")}
                  RegisterName="fullName"
                  register={register}
                  formState={formState}
                  label={t("FULL_NAME")}
                />
                <CustomInputFields
                  type="text"
                  PlaceHolder={t("ENTER_EMAIL_ADDRESS")}
                  RegisterName="email"
                  register={register}
                  formState={formState}
                  label={t("EMAIL_ADDRESS")}
                />
                <CustomPasswordInputField
                  type="Password"
                  PlaceHolder={t("PASSWORD")}
                  RegisterName="password"
                  register={register}
                  formState={formState}
                  label={t("PASSWORD")}
                />
                <CustomPasswordInputField
                  type="Password"
                  PlaceHolder={t("ENTER_CONFIRM_PASSWORD")}
                  RegisterName="confirmPassword"
                  register={register}
                  formState={formState}
                  label={t("CONFIRM_PASSWORD")}
                />
                <CustomSelectFields
                  PlaceHolder={t("SELECT_COUNTRY")}
                  RegisterName="country"
                  register={register}
                  options={countryData}
                  formState={formState}
                  label={t("COUNTRY")}
                  onChangeHandler={handleSelectChangeCountry}
                />

                <Form.Group className="formPadding" controlId="phoneInput">
                  <Form.Label>{t("CONTACT_NUMBER")}</Form.Label>

                  <PhoneInput
                    // {...register("contact", ValidationSchema.contact)}
                    // className={style.phoneInputFieldProperty}
                    {...register("contact", ValidationSchema.contact)}
                    // className={`${style.phoneInputFieldProperty} ${
                    //   errors.contact ? style.errorBorder : ""
                    // }`}
                    // country={"za"}
                    country={countryCode}
                    countryCodeEditable={false}
                    value={`${dialCode} ${contactNumber}`}
                    onChange={(value, country) => {
                      let dialCode = country.dialCode;
                      let phone = value.slice(dialCode.length, value.length);
                      // setErrors("");

                      setDialCode(dialCode);
                      setContactNumber(phone);
                      setValue("contact", phone);
                      setCountryCode(country.countryCode);
                      //
                      // const countryCodeNum = getCountryCallingCode(
                      //   country.countryCode
                      // );
                      // const isValid = isValidNumber(value, country.countryCode);
                      // if (!isValid) {
                      //   errors(
                      //     `Invalid phone number length for ${country.name}`
                      //   );
                      // }
                      // const isValid = isValidNumber(value, country.countryCode);
                      // if (!isValid) {
                      //   errors.contact = `Invalid phone number length for ${country.name}`;
                      // } else {
                      //   errors.contact = null;
                      // }
                    }}
                    onlyCountries={["in", "us"]}
                    style={{ height: "50px" }}
                    // disableDropdown={true}
                    isValid={isContactValid()}
                  />

                  {!isValidPhoneNumber(`+${dialCode}${contactNumber}`) &&
                    formState.isSubmitted &&
                    contactNumber && (
                      <p className={`${style.errorMessage} text-danger`}>
                        Enter valid phone number
                      </p>
                    )}

                  {errors.contact && !contactNumber && (
                    <p className={`${style.errorMessage} text-danger`}>
                      {t(errors.contact?.message)}
                    </p>
                  )}
                  {/* {errors.contact && (
                    <p className={`${style.errorMessage} text-danger`}>
                      {t(errors.contact)}
                    </p>
                  )} */}
                  {/* {contactNumber?.length > 0 && contactNumber?.length <= 4 && (
                    <p className={`${style.errorMessage} text-danger`}>
                      {t("CONTACT_NO_MIN_5_DIGIT")}
                    </p>
                  )} */}
                </Form.Group>
                <CustomInputFields
                  type="text"
                  PlaceHolder={t("ENTER_THE_ADDRESS")}
                  RegisterName="address"
                  register={register}
                  formState={formState}
                  label={t("STREET_ADDRESS")}
                />
                <CustomInputFields
                  type="text"
                  PlaceHolder={t("ENTER_THE_ADDRESS_2")}
                  RegisterName="address2"
                  register={register}
                  formState={formState}
                  label={t("STREET_ADDRESS_2")}
                  setValue={setValue}
                />

                {/* <CustomSelectFields
                  PlaceHolder={t("SELECT_STATE")}
                  RegisterName="state"
                  register={register}
                  options={statesData}
                  formState={formState}
                  label={t("STATE")}
                  onChangeHandler={handleSelectChangeState}
                /> */}
                <CustomSelectFieldsSeachState
                  // PlaceHolder={t("SELECT_STATE")}
                  // RegisterName="state"
                  // register={{ control }}
                  // options={statesOption}
                  // formState={formState}
                  // label={t("STATE")}
                  // onChangeHandler={handleSelectChangeState}
                  PlaceHolder={t("SELECT_STATE")}
                  RegisterName="state"
                  register={{ control }}
                  options={statesOption}
                  formState={formState}
                  label={t("STATE")}
                  onChangeHandler={handleSelectChangeState}
                />

                <CustomInputFields
                  type="text"
                  PlaceHolder={t("ENTER_CITY")}
                  RegisterName="city"
                  register={register}
                  formState={formState}
                  label={t("CITY")}
                />

                <CustomInputFields
                  type="text"
                  PlaceHolder={t("ENTER_ZIP_POSTAL_CODE")}
                  RegisterName="zipCode"
                  register={register}
                  formState={formState}
                  label={t("ZIP_POSTAL_CODE")}
                />
                {usertype === "caregiver" && (
                  <CustomSelectFields
                    PlaceHolder={t("ENTER_SOURCE_ID")}
                    RegisterName="sourceID"
                    register={register}
                    formState={formState}
                    label={t("SOURCE_ID")}
                    options={sourceData}
                    onChangeHandler={(e) => handleChangeSourceId(e)}
                  />
                )}

                <div className="buttonContent pt-2">
                  <Row>
                    <Col sm={8} md={7} className="textCol">
                      <p className="PreviousLink">
                        {t("ALREADY_HAVE_AN_ACCOUNT")}
                        <Link to={"/signin"} className={style.customLinkSignUp}>
                          {t("SIGNIN")}
                        </Link>
                      </p>
                    </Col>
                    <Col
                      sm={4}
                      md={5}
                      className="d-flex justify-content-end setButton"
                    >
                      <CustomButton
                        type="submit"
                        value={t("SIGN_UP")}
                        className="buttonProperty"
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UserSelectionPage;
