import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./AddDevice.module.css";
import {
  CustomInputFieldsModelForm,
  CustomSelectFieldPerson,
  CustomSelectFieldsModelForm,
  CustomSelectFieldsPerson,
} from "../CustomComponent/CustomInputFields/CustomInputField";
import IndependicareApi from "../../Helpers/Api";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Toster from "../../Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Loader from "../LoaderComponent/LoaderComponent";
import { CustomDatePicker } from "../CustomComponent/CustomInputFields/CustomInputField";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserState } from "../../redux/slices/userSlice";
import selectIcon from "../../Assests/Images/Vector.png";
import { Image } from "react-bootstrap";
import CustomSearchField from "../CustomComponent/CustomSearchField/CustomSearchField";
// import Pagination from "../CustomComponent/CustomPagination/Pagination";
import useDebounce from "../../Helpers/Debounce";
import { debounce } from "lodash";
import { LIST_TYPE } from "../../Utils/Constant";

function AddDevice({
  showModal,
  handleCloseModal,
  selectedItem,
  getDeviceList,
  pageNo,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    errors,
    control,
    className,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();

  const token = useSelector((state) => state.user.userToken);
  const [mobileDeviceList, setMobileDeviceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectMobileDevice, setSelectMobileDevice] = useState("");
  const [selectPerson, setSelectPerson] = useState("");
  const [selectDevice, setSelectDevice] = useState("");
  const [selectDate, setSelectDate] = useState(new Date());
  const [updateID, setUpdateID] = useState("");

  const [getPerson, setGetPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [personOption, setPersonOption] = useState([]);
  const [person, setPerson] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [selectedLabelNames, setSelectedLabelNames] = useState([]);

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [showPerson, setShowPerson] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [currentPageForPerson, setCurrentPageForPerson] = useState(1);
  const [addedCaregiverListCountPerson, setAddedCaregiverListCountPerson] =
    useState(0);
  const pageLimit = 10;

  const onPageChangePerson = (pageNumber) => {
    setCurrentPageForPerson(pageNumber);
    getPersonList(pageNumber, searchQuery);
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const handleClosePerson = () => {
    setSearchQuery("");
    setShowPerson(false);
  };
  const handleShowPerson = () => setShowPerson(true);

  const multiSelectRef = useRef(null);

  const listInnerRef = useRef();
  const [isLoadMore, setIsLoadMore] = useState(false);

  const handleScroll = () => {
    if (!isLoadMore) return; // Prevent further API calls if there's no more data to load

    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        // onPageChangePerson(currentPageForPerson + 1);
        let roundedNumber = Math.ceil(
          addedCaregiverListCountPerson / pageLimit
        );
        if (currentPageForPerson >= roundedNumber) {
          setIsLoadMore(false);
        } else {
          onPageChangePerson(currentPageForPerson + 1);
        }
      }
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    const formattedDate = data.maintenanceDate
      ? format(new Date(data.maintenanceDate), "yyyy-MM-dd")
      : "";

    formData.append("mobile_device_id", selectMobileDevice.trim());
    // formData.append("device_type", selectDevice);
    formData.append("device_title", data.deviceTitle);
    // formData.append("device_id", data.deviceID.trim());
    formData.append("device_serial_number", data.deviceSerial);
    formData.append("maintenance_date", formattedDate);

    const personIds = Array.isArray(person) ? person : [];
    formData.append("person_id", personIds.join(","));

    if (!updateID) {
      setIsLoading(true);
      IndependicareApi.saveDevice(formData, token)
        .then((res) => {
          setIsLoading(false);
          const responseCode = STATUS_MSG[res?.data?.code];
          if (res?.code === STATUS_CODE.SUCCESS) {
            Toster(t("ADD_DEVICE_SUCCESFULLY"), "success");
            handleCloseModal();
            getDeviceList(pageNo);
            resetForm();
          } else if (
            res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            res?.data?.code === STATUS_CODE.INACTIVE_USER
          ) {
            dispatch(clearUserState());
            Toster(t("SESSION_EXPIRED"), "error");
            navigate("/signin");
          } else if (
            res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            res?.code === STATUS_CODE.DEVICE_TITLE_ALREADY_EXIST
          ) {
            Toster(res?.data?.message, "error");
          } else {
            Toster(t(responseCode), "error");
          }
        })
        .catch((error) => {
          Toster(error, "error");
        });
    } else {
      setIsLoading(true);
      IndependicareApi.updateDevice(formData, updateID, token)
        .then((res) => {
          setIsLoading(false);
          const responseCode = STATUS_MSG[res?.data?.code];
          if (res?.code === STATUS_CODE.SUCCESS) {
            Toster(t("UPDATE_DEVICE_SUCCESFULLY"), "success");
            getDeviceList(pageNo);
            handleCloseModal();
            // updateID("");
            setUpdateID("");
            resetForm();
          } else if (
            res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            res?.data?.code === STATUS_CODE.INACTIVE_USER
          ) {
            dispatch(clearUserState());
            Toster(t("SESSION_EXPIRED"), "error");
            navigate("/signin");
          } else if (
            res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            res?.code === STATUS_CODE.DEVICE_TITLE_ALREADY_EXIST
          ) {
            Toster(res?.data?.message, "error");
          } else {
            Toster(t(responseCode), "error");
          }
        })
        .catch((error) => {
          Toster(error, "error");
        });
    }
  };

  // const deviceType = [
  //   { id: "moveable", value: "moveable", name: "Moveable" },
  //   { id: "fixed", value: "fixed", name: "Fixed" },
  // ];

  const handleSelectChangeDevice = (event) => {
    setSelectMobileDevice(event.target.value);
    clearErrors("mobileDevice");
  };

  // const handleSelectChange = (event) => {
  //   setSelectDevice(event.target.value);
  // };

  // get mobile device list
  const getMobileDeviceList = (token) => {
    IndependicareApi.getMobileDevice(token)
      .then((res) => {
        const responseCode = STATUS_MSG[res?.data?.code];
        if (res?.code === STATUS_CODE.SUCCESS) {
          setMobileDeviceList(res.data.mobile_device_list);
        } else if (
          res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          res?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(res?.data?.message, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getMobileDeviceList(token);
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setValue("deviceTitle", selectedItem.device_title);
      // setValue("deviceID", selectedItem.device_id);
      setValue("deviceSerial", selectedItem.device_serial_number);

      const dateString = selectedItem.maintenance_date;
      const parts = dateString.split("/");

      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      const dateObject = new Date(year, month, day);

      setSelectDate(dateObject);
      setValue("maintenanceDate", dateObject);
      setValue("mobileDevice", selectedItem.mobile_device_type);
      // setValue("deviceType", selectedItem.device_type);
      setSelectMobileDevice(selectedItem.mobile_device_type);
      // setSelectDevice(selectedItem.device_type);
      setUpdateID(selectedItem.id);

      // if (selectedItem.person_id) {
      //   const personIds = Array.isArray(selectedItem.person_id)
      //     ? selectedItem.person_id
      //     : selectedItem.person_id.toString().split(",");
      //   setSelectedPerson(personIds);
      //   setPerson(personIds);
      //   setPersonName(selectedItem.person_names);
      // } else {
      //   setSelectedPerson([]);
      // }
      if (selectedItem.person_id) {
        const personIds = Array.isArray(selectedItem.person_id)
          ? selectedItem.person_id
          : selectedItem.person_id.toString().split(",");

        setSelectedPerson(personIds);
        setPerson(personIds);
        const selectedNames = personOption
          .filter((option) => personIds.includes(option.value))
          .map((option) => option.label);

        // setPerson(selectedNames);
        const arr = selectedItem?.person_names
          ?.split(",")
          .map((name) => name.trim());
        setPersonName([...arr]);
        setSelectedLabelNames([...arr]);

        // selectedLabelNames;
        setValue("person", personIds);
      } else {
        //  setSelectedPerson([]);
        //setPerson([]);
      }
    } else {
      reset();
      //  setSelectedPerson([]);
    }
  }, [selectedItem]);

  const debouncedSearchPerson = useDebounce((term) => {
    getPersonList(1, term, 1);
  }, 500);

  const resetForm = () => {
    reset({
      deviceTitle: "",
      // deviceID: "",
      deviceSerial: "",
      maintenanceDate: new Date(),
      mobileDevice: "",
      deviceType: "",
      personName: [],
    });

    setSelectMobileDevice("");
    // setSelectDevice("");
    setSelectDate(new Date());
    setUpdateID("");
    setSelectedPerson([]);
    setSelectedLabelNames([]);
    setPerson([]);
    setPersonName([]);

    if (multiSelectRef.current) {
      if (typeof multiSelectRef.current.reset === "function") {
        multiSelectRef.current.reset();
      } else {
        multiSelectRef.current.value = "";
      }
    }
  };

  const getPersonList = (currentPageForPerson, searchQuery) => {
    IndependicareApi.getPersonList(
      token,
      currentPageForPerson,
      searchQuery,
      LIST_TYPE.ACTIVE
    ).then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const newPersons = res?.data?.person_list;
        if (currentPageForPerson === 1) {
          // If it's the first page, replace the list
          setGetPerson(newPersons);
        } else {
          // Otherwise, append new data to existing list
          setGetPerson((prevPersons) => [...prevPersons, ...newPersons]);
        }
        setAddedCaregiverListCountPerson(res?.data?.count);
        setIsLoadMore(res?.data?.loadMore);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  useEffect(() => {
    getPersonList(currentPageForPerson, searchQuery);
  }, [showPerson]);

  useEffect(() => {
    const options = getPerson.map((person) => ({
      value: person.id,
      label: person.person_name,
    }));
    setPersonOption(options);
  }, [getPerson]);

  const handleSubmitPerson = () => {
    if (selectedPerson.length === 0) {
      setPersonName([]);
      setShowValidation(true);
      handleClosePerson();
    } else {
      setShowValidation(false);
      setPerson(selectedPerson);
      setPersonName(selectedLabelNames.join(","));
      handleClosePerson();
    }
    setCurrentPageForPerson(1);
  };

  const handleChange = (event, label) => {
    const id = event.target.value;
    const checked = event.target.checked;
    setSelectedPerson((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
    if (checked) {
      setSelectedLabelNames([...selectedLabelNames, label]);
    } else {
      const temparr = selectedLabelNames?.filter((item) => item != label);
      setSelectedLabelNames([...temparr]);
    }
  };

  const selectedLabels = personOption
    .filter((option) => selectedPerson.includes(option.value))
    .map((option) => option.label)
    .join(", ");

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          handleCloseModal();
          resetForm();
        }}
      >
        <Modal.Header className="d-flex justify-content-between">
          {/* <Modal.Title className="setModelTitleProperty"> */}
          <Modal.Title className={`setModelTitleProperty ${styles.modelTitle}`}>
            {updateID ? t("UPDATE_DEVICE") : t("ADD_DEVICE")}
            {/* {t("ADD_DEVICE")} */}
          </Modal.Title>
          <CancelIcon
            className="closeIconProperty"
            onClick={() => {
              handleCloseModal();
              resetForm();
            }}
          />
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && <Loader />}
            {/* person input fiels start */}

            <div className={`border-none ${styles.personField}`}>
              <Form.Group className="mb-3">
                <Form.Label className={styles.personLabel}>
                  {t("PERSON")}
                </Form.Label>
                <Form.Control
                  type="text"
                  // value={person}
                  value={personName}
                  placeholder={t("SELECT_PERSON")}
                  onClick={handleShowPerson}
                  readOnly
                  className={`${styles.addDeviceProperty} ${styles.inputStyle}`}
                  isInvalid={showValidation}
                />
                <Form.Control.Feedback type="invalid">
                  {t("PLEASE_SELECT_AT_LEAST_ONE_PERSON")}
                </Form.Control.Feedback>
              </Form.Group>
              <Modal show={showPerson} onHide={handleClosePerson}>
                <Modal.Header className="d-flex justify-content-between">
                  <Modal.Title className="setModelTitleProperty">
                    {t("SELECT_OPTIONS")}
                  </Modal.Title>
                  <CancelIcon
                    className="closeIconProperty"
                    onClick={handleClosePerson}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center">
                        <CustomSearchField
                          setSearchQuery={(val) => {
                            setSearchQuery(val);
                            debouncedSearchPerson(val);
                          }}
                        />
                      </div>
                      <div
                        className={styles.personListContainer}
                        ref={listInnerRef}
                        onScroll={debounce(handleScroll, 100)}
                      >
                        {personOption && personOption.length > 0 ? (
                          personOption.map((option) => (
                            <div
                              key={option.id}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Form.Label htmlFor={`person-${option.value}`}>
                                {option.label}
                              </Form.Label>
                              <Form.Check
                                key={option.value}
                                type="checkbox"
                                id={`person-${option.value}`}
                                value={option.value}
                                checked={selectedPerson.includes(option.value)}
                                onChange={(e) => handleChange(e, option.label)}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center p-2">
                            <p>{t("NO_RECORD_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="w-100"
                    // onClick={handleClose}
                    onClick={handleSubmitPerson}
                  >
                    {t("Submit")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            {/* person input fiels end */}

            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_DEVICE_TITLE")}
              RegisterName="deviceTitle"
              register={register}
              formState={formState}
              label={t("DEVICE_TITLE_MODEL")}
              className={styles.addDeviceProperty}
              onChange={(e) => {
                setValue("deviceTitle", e.target.value.replace(/\s/g, " "), {
                  shouldValidate: true,
                });
              }}
            />

            <CustomSelectFieldsModelForm
              PlaceHolder={t("SELECT_MOBILE_DEVICE_TYPE")}
              RegisterName="mobileDevice"
              register={register}
              options={mobileDeviceList}
              formState={formState}
              label={t("MOBILE_DEVICE_TYPE")}
              value={selectMobileDevice}
              onChange={handleSelectChangeDevice}
              className={styles.addDeviceProperty}
            />
            <Modal show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>{t("MOBILE_DEVICE_TYPE")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomSelectFieldsModelForm
                  PlaceHolder={t("SELECT_MOBILE_DEVICE_TYPE")}
                  RegisterName="deviceType"
                  register={register}
                  options={mobileDeviceList}
                  formState={formState}
                  label={t("MOBILE_DEVICE_TYPE")}
                  value={selectMobileDevice}
                  onChange={handleSelectChangeDevice}
                  className={styles.addDeviceProperty}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="AddSiteSubmitButton"
                  type="submit"
                  onClick={handleClose2}
                >
                  {t("DONE")}
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <CustomSelectFieldsModelForm
              PlaceHolder={t("SELECT_DEVICE_TYPE")}
              RegisterName="deviceType"
              options={deviceType}
              register={register}
              formState={formState}
              label={t("DEVICE_TYPE")}
              value={selectDevice}
              onChange={handleSelectChange}
              className={`selectIcon ${styles.addDeviceProperty}`}
            />
            <Modal show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>{t("SELECT_DEVICE_TYPE")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomSelectFieldsModelForm
                  PlaceHolder={t("SELECT_DEVICE_TYPE")}
                  RegisterName="deviceType"
                  options={deviceType}
                  register={register}
                  formState={formState}
                  label={t("DEVICE_TYPE")}
                  value={selectDevice}
                  onChange={handleSelectChange}
                  className={`selectIcon ${styles.addDeviceProperty}`}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="AddSiteSubmitButton"
                  type="submit"
                  onClick={handleClose3}
                >
                  {t("DONE")}
                </Button>
              </Modal.Footer>
            </Modal> */}

            {/* <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_DEVICE_ID")}
              RegisterName="deviceID"
              register={register}
              formState={formState}
              label={t("DEVICE_ID")}
              className={styles.addDeviceProperty}
              onChange={(e) => {
                setValue("deviceID", e.target.value.replace(/\s/g, " "), {
                  shouldValidate: true,
                });
              }}
            /> */}

            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_DEVICE_SERIAL_NUMBER")}
              RegisterName="deviceSerial"
              register={register}
              formState={formState}
              label={t("DEVICE_SERIAL_NUMBER")}
              className={styles.addDeviceProperty}
              onChange={(e) => {
                setValue("deviceSerial", e.target.value.replace(/\s/g, " "), {
                  shouldValidate: true,
                });
              }}
            />

            <CustomDatePicker
              control={control}
              RegisterName="maintenanceDate"
              register={register}
              formState={formState}
              label={t("DEVICE_MAINTENANCE_DATE")}
              PlaceHolder={t("SELECT_DEVICE_MAINTENANCE_DATE")}
              className={`${styles.addDeviceProperty}`}
              defaultValue={selectDate}
              rules={{
                required: t("MAINTENANCE_DATE_IS_REQUIRED"),
                validate: (value) => (value ? true : t("INVALID_DATE")),
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button className="AddSiteSubmitButton" type="submit">
              {/* {t("ADD_C6_DEVICE")} */}
              {updateID ? t("UPDATE_DEVICE") : t("ADD_DEVICE")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
export default AddDevice;
