import React from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { FiSearch } from "react-icons/fi";
import styles from "../CustomSearchField/CustomSearchField.module.css";

function CustomSearchField({ setSearchQuery, value }) {
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className={styles.searchFeild}>
      <Form.Control
        value={value}
        size="sm"
        type="search"
        placeholder={t("SEARCH")}
        onChange={handleInputChange}
        onEmptied={handleInputChange}
      />
      <FiSearch />
    </div>
  );
}
export default CustomSearchField;
