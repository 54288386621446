import React, { useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../Assests/Images/edit.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
  // Pagination,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import AddPerson from "./AddPerson";
import { useSelector } from "react-redux";
import IndependicareApi from "../../Helpers/Api";
import { personInfo } from "../../Utils/MockData";
import styles from "../../Components/AddPerson/ShowPerson.module.css";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { useNavigate } from "react-router-dom";
import { clearUserState } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import Toster from "../../Toster/Toster";
import deleteImage from "../../Assests/Images/delete_image.png";
import { ACTIVE_TYPE } from "../../Utils/Constant";
import PlayAudioBtn from "../../Assests/Images/PlayAudioBtn.png";
import ReactAudioPlayer from "react-audio-player";
import Loader from "../LoaderComponent/LoaderComponent";
import ReactPaginate from "react-paginate";
import debounce from "debounce";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import useDebounce from "../../Helpers/Debounce";

function ShowPerson() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLimit = 10;

  const [getPerson, setGetPerson] = useState([]);

  const [selectedLevelStatus, setSelectedLevelStatus] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  // const handleShowModal = () => setShowModal(true);

  const [isEdit, setIsEdit] = useState(false);
  const [personId, setPersonId] = useState();
  const [showDelete, setShowdelete] = useState(false);
  const handleCloseDelete = () => setShowdelete(false);
  const handleShowDelete = () => setShowdelete(true);
  // const handleCloseAudioModal = () => setShowAudioModal(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [showLevelstatus, setShowLevel] = useState(false);
  const handleCloseStatusLevel = () => setShowLevel(false);
  const handleShowStatusLevel = () => setShowLevel(true);

  const pageNo = 1;
  const token = useSelector((state) => state.user.userToken);
  const [pageno, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPageno, setTotalPageno] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const [detailAudio, setDeatilAudio] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [audioType, setAudioType] = useState(null);

  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    getPersonList(1, "");
  }, []);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getPersonList(pageNumber, searchQuery);
  };

  const debouncedSearch = useDebounce((term) => {
    if (isLoadMore) {
      getPersonList(currentPage, term);
    } else {
      getPersonList(1, term);
      setCurrentPage(1);
    }
    if (term == "") {
      getPersonList(1, term);
      setCurrentPage(1);
      setPageNo(1);
    }
  }, 500);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };

  const debouncedStackHolder = useCallback(
    debounce((pageno, searchTerm) => {
      getPersonList(pageno, searchTerm);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    // setSearchTerm(newSearchTerm);
    debouncedStackHolder(1, newSearchTerm);
  };

  const getPersonList = (pageno, searchTerm) => {
    setIsLoading(true);
    IndependicareApi.getPersonList(token, pageno, searchTerm).then((res) => {
      setCurrentPage(pageno);
      setSearchQuery(searchTerm);
      setIsLoading(false);
      if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else if (res?.code == STATUS_CODE.SUCCESS) {
        setGetPerson(res.data.person_list);
        setTotalCount(res?.data?.count);
        setIsLoadMore(res?.data?.loadMore);
      }
    });
  };

  // useEffect(() => {
  //   getPersonList(pageNo, searchTerm);
  // }, []);

  // const playAudio = (audioUrl) => {
  //   setCurrentAudio(audioUrl);
  //   setShowAudioModal(true);
  // };
  const playAudio = (audioUrl, type) => {
    setCurrentAudio(audioUrl); // Set the current audio URL
    setAudioType(type); // Set the type of audio (alert_tone or prompt_intro)
    setShowAudioModal(true); // Show the modal
  };
  {
    /*=====Delete Person======*/
  }

  const handleCloseAudioModal = () => {
    setShowAudioModal(false);
    setCurrentAudio(null);
    setAudioType(null); // Reset the audio type when modal closes
  };

  const PersonDelete = (personId) => {
    setIsLoading(true);
    IndependicareApi.deletePerson(token, personId).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);

      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("PERSON_DELETED_SUCCESSFULLY"), "success");
        getPersonList(pageNo, searchQuery);
        setShowdelete(false);
      } else if (
        response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
        response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
      ) {
        Toster(response?.code?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      setShowdelete(false);
    });
  };

  {
    /*=========Person status ===============*/
  }

  const Statusperson = (personId) => {
    setIsLoading(true);
    IndependicareApi.Personstatus(token, personId).then((response) => {
      const responseCode = STATUS_MSG[response?.data?.code];

      if (response?.code === STATUS_CODE.SUCCESS) {
        Toster(t("PERSON_STATUS_UPDATED_SUCCESSFULLY"), "success");
        getPersonList(pageNo, searchQuery);
        setIsLoading(false);
        handleCloseStatusLevel();
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }

      //
      // getPersonList(pageNo, searchQuery);
      // setIsLoading(false);
      // handleCloseStatusLevel();
    });
  };

  const filteredList = getPerson.filter((item) =>
    item.person_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div>
        <Container>
          <Row className="align-items-center">
            <Col
              className="setSearchInputField"
              style={{ paddingBottom: "10px", paddingTop: "10px" }}
            >
              <CustomSearchField
                setSearchQuery={(val) => {
                  setSearchQuery(val);
                  debouncedSearch(val);
                }}
              />
            </Col>
            <Col>
              <div className="modelButtonPropwerty">
                <Button
                  className="modalTextButton"
                  type="button"
                  onClick={() => {
                    setShowModal(true);
                    setIsEdit(false);
                    setPersonId("");
                  }}
                >
                  {t("ADD_PERSON")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <div className={styles.eventTable}>
            <Table className={styles.customTable} striped>
              <thead className={styles.tableHeadProperty}>
                <tr>
                  <th>{t("PERSON_NAME")}</th>
                  <th>{t("ALERT_TONE_AUDIO")}</th>
                  <th>{t("PROMPT_INTRO_TEXT")}</th>
                  <th>{t("PROMPT_INTRO_AUDIO")}</th>
                  <th>{t("LANGUAGE_TO_USE")}</th>
                  <th>{t("PROMPT_OPTION")}</th>
                  <th>{t("NOTES")}</th>
                  <th>{t("STATUS")}</th>
                  <th>{t("ACTION")}</th>
                </tr>
              </thead>
              <tbody>
                {getPerson &&
                  getPerson.length > 0 &&
                  getPerson.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/person-detail", {
                              state: { person_id: item.id },
                            })
                          }
                        >
                          {item.person_name}
                        </td>
                        <td>
                          {item.alert_tone_audio ? (
                            <Image
                              src={PlayAudioBtn}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                playAudio(item.alert_tone_audio, "alert_tone")
                              }
                            />
                          ) : (
                            t("NA")
                          )}
                        </td>
                        <td class="tdtextNotes tdtext">
                          <div class="tdContentWrapper">
                            {item.prompt_intro_text.length === 0
                              ? t("NA")
                              : item.prompt_intro_text}
                          </div>
                        </td>

                        <td>
                          {item.prompt_intro_audio ? (
                            <Image
                              src={PlayAudioBtn}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                playAudio(
                                  item.prompt_intro_audio,
                                  "prompt_intro"
                                )
                              }
                            />
                          ) : (
                            t("NA")
                          )}
                        </td>

                        <td>{item.language}</td>
                        <td>{item.prompt_option}</td>
                        {/* <td
                          className={styles.notesText}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {truncateText(item.notes, 5)}
                        </td> */}
                        {/* <td className="tdtextNotes">{item.notes}</td> */}
                        <td
                          // style={{
                          //   ...tdAlignClass,
                          // }}
                          className="tdtextNotes tdtext"
                        >
                          {/* {item.notes} */}
                          <div className="tdContentWrapper">
                            {item.notes.length === 0 ? t("NA") : item.notes}
                          </div>
                        </td>
                        <td>
                          {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td>
                          <Dropdown className="setParentDropdown">
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div className="d-flex justify-content-around dropdownTextProperty">
                                <Dropdown.Item
                                  onClick={() => {
                                    setShowModal(true);
                                    setIsEdit(true);
                                    setPersonId(item.id);
                                    // setDeatilAudio(item.prompt_intro_audio);
                                  }}
                                >
                                  {t("EDIT")}
                                </Dropdown.Item>
                                <Image src={edit} className="dropdownIcons" />
                              </div>
                              <div className="d-flex justify-content-around dropdownTextProperty">
                                <Dropdown.Item
                                  onClick={() => {
                                    handleShowDelete();
                                    setPersonId(item.id);
                                  }}
                                >
                                  {t("DELETE")}
                                </Dropdown.Item>
                                <DeleteOutlineIcon className="iconColor" />
                              </div>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleShowStatusLevel();
                                  setPersonId(item.id);
                                  setSelectedLevelStatus(item.is_active);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>
                                    {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                                      ? "Inactive"
                                      : "Active"}
                                  </span>
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Container>
        <div className={styles.paginationContainer}>
          <Pagination
            count={totalCount}
            pageLimit={pageLimit}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        </div>
        <div className={styles.noRecord}>
          <p>
            {!isLoading && getPerson.length == 0 ? t("NO_RECORD_FOUND") : ""}
          </p>
        </div>
      </div>
      <AddPerson
        // show={handleShowModal}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        setShowModal={setShowModal}
        getPersonList={getPersonList}
        isEdit={isEdit}
        personId={personId}
        detailAudio={detailAudio}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        pageNo={pageNo}
        searchQuery={searchTerm}
      />

      {/*=========person-delete-Modal==============*/}
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PERSON")}</p>
          </div>
          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              PersonDelete(personId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      {/*=========Person-Status-Modal==============*/}
      <Modal show={showLevelstatus} onHide={handleCloseStatusLevel}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseStatusLevel}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            {selectedLevelStatus === ACTIVE_TYPE.IS_ACTIVE ? (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_PERSON")}</p>
            ) : (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_PERSON")}</p>
            )}
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              Statusperson(personId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      {/*=========Prompt-intro-TTS-Modal==============*/}
      <Modal show={showAudioModal} onHide={handleCloseAudioModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className={styles.audioIntro}>
              <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
            </div> */}
            <div className={styles.audioIntro}>
              {/* Conditional rendering based on audioType */}
              {audioType === "alert_tone" ? (
                <h4>{t("ALERT_AUDIO_TONE")}</h4>
              ) : (
                <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAudio && (
            <ReactAudioPlayer
              src={currentAudio}
              autoPlay
              controls
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ShowPerson;
