import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Loader from "../../LoaderComponent/LoaderComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Toster/Toster";
import styles from "./LocationType.module.css";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import { CustomInputFields } from "../../CustomComponent/CustomInputFields/CustomInputField";
import CancelIcon from "@mui/icons-material/Cancel";

const AddLocationTypeModel = ({
  show,
  handleClose,
  isEdit,
  locationId,
  handleGetData,
  searchTerm,
}) => {
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const addLocationType = (postData) => {
    IndependicareApi.addLocation(userToken, postData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("ADD_LOCATION_SUCCESSFULLY"), "success");
          reset();
          handleGetData(searchTerm);
          handleModalClose();
        } else if (
          response?.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response?.code?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const updateLocationType = (postData) => {
    IndependicareApi.updateLocationType(userToken, postData, locationId).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("LOCATION_UPDATED_SUCCESSFULLY"), "success");
            reset();
            handleGetData(searchTerm);
            handleModalClose();
          } else if (
            response?.code == STATUS_CODE.VALIDATIONS_ERROR ||
            response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR
          ) {
            Toster(response?.code?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = new FormData();
    postData.append("title", data.locationName);
    !isEdit ? addLocationType(postData) : updateLocationType(postData);
  };

  useEffect(() => {
    if (isEdit && locationId) {
      IndependicareApi.fetchLocationeDetail(userToken, locationId).then(
        (response) => {
          setValue("locationName", response?.data?.location_type?.title);
          clearErrors("locationName");
        }
      );
    } else {
      setValue("locationName", "");
      clearErrors("locationName", "");
    }
  }, [isEdit, locationId, show]);

  const handleModalClose = () => {
    handleClose();
    reset();
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      {isLoading && <Loader />}
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>
          {isEdit ? t("UPDATE_LOCATION") : t("ADD_LOCATION")}
        </Modal.Title>
        <CancelIcon className="closeIconProperty" onClick={handleModalClose} />
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <CustomInputFields
            type="text"
            PlaceHolder={t("ENTR_LOCATION")}
            RegisterName="locationName"
            register={register}
            formState={formState}
            label={t("LOCATION")}
            setValue={setValue}
          />
        </Modal.Body>
        <Modal.Footer className={styles.addNewTitle}>
          <Button variant="" type="submit">
            {/* {t("DONE")} */}
            {isEdit ? t("UPDATE") : t("DONE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddLocationTypeModel;
