export const STATUS_MSG = {
  100: "HEADER_IS_MISSING",
  101: "DEVICE_TYPE_NOT_ALLOWED",
  102: "INVALID_API_KEY",
  103: "A_TOKEN_IS_REQUIRED_FOR_AUTHENTICATION",
  104: "INVALID_TOKEN",
  105: "VALIDATIONS_ERROR",
  106: "INVALID_CODE",
  107: "INVALID_ID",
  108: "ACCESS_DENIED_TO_USER_ENTER_VALID_TOKEN",
  109: "INVALID_USER",
  110: "PROFILE_IMAGE_IS_NOT_FOUND",
  111: "INVALID_LOGIN_CREDENTIALS",
  112: "ACCOUNT_NOT_FOUND",
  113: "INACTIVE_USER",
  114: "USER_ACCOUNT_IS_DELETE",
  115: "OLD_PASSWORD_IS_NOT_MATCH",
  116: "INVALID_LEVEL_ID",
  117: "EMAIL_EXIST",
  118: "PHONE_NUMBER_EXIST",
  119: "DEVICE_ID_ALREADY_EXIST",
  120: "RECORD_NOT_FOUND",
  121: "SERIAL_NUMBER_ALREADY_EXIST",
  122: "INVALID_FILE_EXTENSION",
  123: "EMAIL_SEND_FAIL",
  124: "INVALID_USER_TYPE",
  125: "OTP_NOT_MATCH",
  126: "INVALID_SOURCE_ID",
  127: "TOKEN_EXPIRE",
  128: "RECORD_EXIST",
  129: "INVALID_FILE_EXTENTION",
  130: "SIZE_EXCEED_LIMIT",
  131: "INVALID_EVENT_TYPE_ID",
  132: "INVALID_REMINDER_ID",
  133: "INVALID_LEVEL_ID",
  134: "INVALID_PERSON_ID",
  135: "INVALID_LOCATION_TYPE_ID",
  136: "INVALID_SUB_LOCATION_TYPE_ID",
  137: "UNIQUE_ID_EXIST",
  138: "IT_CAN_NOT_BE_DELETED_ALREADY_USED",
  139: "INVALID_ADDED_CAREGIVER_ID",
  140: "INVALID_RELATION_ID",
  141: "INVALID_PERMISSION_ID",
  142: "INVALID_DEVICE_ID",
  143: "INVALID_TAG_ID",
  144: "ASSIGNED_SENIOR_ID_CAN_NOT_BE_DELETED",
  145: "INVALID_CONTENT_PAGE_NAME",
  146: "THE_TIME_MUST_BE_CURRENT_OR_FUTURE_TIME",
  147: "PERSON_NAME_EXIST",
  148: "INVALID_ASSIGN_ID",
  200: "SUCCESS",
  149: "SCHEDULE_OVERLAP",
  150: "STAKEHOLDER_CAREGIVER_ALREADY_ASSIGN_WITH_PERSON",
  151: "INVALID_SCHEDULE_DELETED_ID",
  152: "INVALID_SCHEDULE_ID",
  153: "DEVICE_TITLE_ALREADY_EXIST",
  154: "TAG_TITLE_IS_ALREADY_EXIST",
  500: "INTERNAL_SERVER_ERROR",
};
