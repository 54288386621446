import React from 'react';
import Image from 'react-bootstrap/Image';
import logoImg from '../../Assests/Images/IndependiCareLogo21.png';
import styles from'./Logo.module.css';
import { Link } from 'react-router-dom';

function Logo() {
  return (
    <div>
        <Link to={"/"}><Image src={logoImg} className={styles.logoImg}/></Link>
    </div>
  );
}

export default Logo;
