// import * as Yup from 'yup';

import { toBeRequired } from "@testing-library/jest-dom/matchers";

export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const ValidationSchema = {
  email: {
    required: "EMAIL_REQUIRED",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "VALID_EMAIL_ADDRESS",
    },
    minLength: {
      value: 5,
      message: "EMAIL_MUST_5_CH",
    },
    maxLength: {
      value: 100,
      message: "EMAIL_NOT_MORE_THAN_100_CH",
    },
  },

  password: {
    required: "PASSWORD_REQUIRE",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      // value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*])[A-Za-z\d~!@#$%^&*]{6,12}$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },

  newPassword: {
    required: "NEW_PASSWORD_REQUIRE",
    minLength: {
      value: 6,
      message: "NEW_PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "NEW_PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "NEW_PASSWORD_MUST_CONTAIN",
    },
  },
  confirmPassword: {
    // required: "PASSWORD_NOT_MATCHED",
    required: "CONFIRM_PASSWORD_REQUIRED",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "PASSWORD_MUST_CONTAIN",
    },
    validate: (value, formData) =>
      value === formData.password || "PASSWORD_NOT_MATCHED",
  },
  confirmPasswordProfile: {
    required: "PASSWORD_NOT_MATCHED",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "PASSWORD_MUST_CONTAIN",
    },
    validate: (value, formData) =>
      value === formData.newPassword || "PASSWORD_NOT_MATCHED",
  },
  fullName: {
    required: "FULLNAME_IS_REQUIRED",
    minLength: {
      value: 2,
      message: "FULLNAME_MUST_2_CH",
    },
    maxLength: {
      value: 50,
      message: "FULLNAME_NOT_MORE_THAN_50_CH",
    },
    // pattern: {
    //   value: /^[a-zA-Z\s]*$/,
    //   message: "FULLNAME_SHOULD_ONLY_CONTAIN_ALPHABETS_AND_SPACES",
    // },
  },

  contact: {
    required: "PLEASE_ENTER_CONTACT_NO",
    // minLength: {
    //   value: 5,
    //   message: "CONTACT_NO_MIN_5_DIGIT",
    // },
    maxLength: {
      value: 15,
      message: "CONTACT_NO_MAX_15_DIGIT",
    },
  },
  dialCode: {
    required: "PLEASE_ENTER_DIAL_CODE",
    minLength: {
      value: 2,
      message: "DIALCODE_MIN_2_DIGIT",
    },
    maxLength: {
      value: 4,
      message: "DIALCODE_MAX_4_DIGIT",
    },
  },
  zipCode: {
    required: "ENTER_ZIP_POSTAL_CODE",
    // pattern: {
    //   value: /^[0-9]{2,10}$/,
    //   message: "Invalid ZIP/Postal code",
    // },
    minLength: {
      value: 2,
      message: "ZIP_CODE_MUST_AT_LEAST_2_DIGIT",
    },
    maxLength: {
      value: 10,
      message: "ZIP_CODE_SHOULD_NOT_MORE_THAN_10_DIGIT",
    },
  },
  address: {
    required: "ENTER_THE_ADDRESS",
    minLength: {
      value: 3,
      message: "STREET_ADDRESS_ONE_MUST_MIN_3_CHARACTERS",
    },
    maxLength: {
      value: 250,
      message: "STREET_ADDRESS_ONE_MAX_250_CHARACTERS",
    },
  },
  address2: {
    minLength: {
      value: 3,
      message: "STREET_ADDRESS_TWO_MUST_MIN_3_CHARACTERS",
    },
    maxLength: {
      value: 250,
      message: "STREET_ADDRESS_TWO_MAX_250_CHARACTERS",
    },
  },

  contactEmail: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "VALID_EMAIL_ADDRESS",
    },
  },

  country: {
    required: "PLS_SELECT_COUNTRY",
  },
  state: {
    required: "PLS_SELECT_STATE",
  },
  city: {
    required: "PLS_ENTER_CITY",
    minLength: {
      value: 2,
      message: "CITY_MIN_2_CH",
    },
    maxLength: {
      value: 50,
      message: "CITY_MAX_50_CH",
    },
  },
  sourceID: {
    required: "PLS_SELECT_SOURCEID",
    minLength: {
      value: 2,
      message: "SOURCEID_MIN_2_CH",
    },
    maxLength: {
      value: 6,
      message: "SOURCEID_MAX_6_CH",
    },
  },
  passCode: {
    required: "PASSCODE_REQUIRED",
    pattern: {
      value: /^[0-9]{4}(?:-[0-9]{4})?$/,
      message: "ENTER_VALID_PASSCODE",
    },
    minLength: {
      value: 4,
      message: "PASSCODE_MINIMUM_4_DIGIT",
    },
    maxLength: {
      value: 4,
      message: "PASSCODE_MAXIMUM_4_DIGIT",
    },
  },
  // validation for device component

  deviceTitle: {
    required: "DEVICE_TITLE_REQUIRED",
    minLength: {
      value: 2,
      message: "DEVICE_TITLE_MUST_BE_ATLEAST_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "DEVICE_TITLE_SHOULD_NOT_MORE_THAN_50_CHARACTERS",
    },
    validate: {
      noWhitespace: (value) =>
        value.trim() !== "" || "DEVICE_TITLE_SHOULD_NOT_BE_WHITESPACES_ONLY",
    },
  },
  mobileDevice: {
    required: "MOBILE_DEVICE_REQUIRED",
    minLength: {
      value: 2,
      message: "MOBILE_DEVICE_MUST_BE_ATLEAST_2_CHARACTERS",
    },
    maxLength: {
      value: 10,
      message: "MOBILE_DEVICE_SHOULD_NOT_MORE_THAN_10_CHARACTERS",
    },
  },
  deviceType: {
    required: "DEVICE_TYPE_REQUIRED",
  },
  deviceID: {
    required: "DEVICE_ID_REQUIRED",
    minLength: {
      value: 2,
      message: "DEVICE_ID_MUST_BE_ATLEAST_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "DEVICE_ID_SHOULD_NOT_MORE_THAN_50_CHARACTERS",
    },
    validate: {
      noWhitespace: (value) =>
        value.trim() !== "" || "DEVICE_ID_SHOULD_NOT_BE_WHITESPACES_ONLY",
    },
  },
  device: {
    required: "PLEASE_SELECT_AT_LEAST_ONE_DEVICE",
  },
  deviceSerial: {
    required: "DEVICE_SERIAL_REQUIRED",
    minLength: {
      value: 2,
      message: "DEVICE_SERIAL_NUMBER_MUST_BE_ATLEAST_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "DEVICE_SERIAL_NUMBER_SHOULD_NOT_MORE_THAN_50_CHARACTERS",
    },
    validate: {
      noWhitespace: (value) =>
        value.trim() !== "" ||
        "DEVICE_SERIAL_NUMBER_SHOULD_NOT_BE_WHITESPACES_ONLY",
    },
  },
  maintenanceDate: {
    required: "MAINTENANCE_DATE_REQUIRED",
  },

  title: {
    required: "TITLE_REQUIRED",
    minLength: {
      value: 2,
      message: "NAME_MAY_HAVE_MINIMUM_OF_2_CHARACTERS",
      // validate: (value) =>
      //   value.trim() !== "" || "Username cannot be empty or just spaces",
    },
    maxLength: {
      value: 50,
      message: "NAME_MAY_HAVE_MAXIMUM_OF_50_CHARACTERS",
    },
  },

  level: {
    required: "LEVEL_REQUIRED",
  },

  relation: {
    required: "RELATION_REQUIRED",
    minLength: {
      value: 2,
      message: "RELATION_NAME_MAY_HAVE_MINIMUM_OF_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "RELATION_NAME_MAY_HAVE_MAXIMUM_OF_50_CHARACTERS",
    },
  },
  time: {
    required: "TIME_IS_REQUIRED",
  },
  eventType: {
    required: "EVENT_TYPE_IS_REQUIRED",
  },

  personName: {
    required: "PERSON_NAME_IS_REQUIRED",
    minLength: {
      value: 2,
      message: "PERSON_NAME_MUST_2_CH",
    },
    maxLength: {
      value: 50,
      message: "PERSON_NAME_NOT_MORE_THAN_50_CH",
    },
  },

  promptIntroText: {
    // required: "PROMPT_INTRO_TEXT_IS_REQUIRED",
    minLength: {
      value: 2,
      message: "PROMPT_INTRO_TEXT_MUST_2_CH",
    },
    maxLength: {
      value: 50,
      message: "PROMPT_INTRO_TEXT_NOT_MORE_THAN_50_CH",
    },
  },

  tts_prompt: {
    required: "TTS_PROMPT_TEXT_IS_REQUIRED",
    minLength: {
      value: 2,
      message: "TTS_PROMPT_TEXT_MUST_2_CH",
    },
    maxLength: {
      value: 50,
      message: "TTS_PROMPT_TEXT_NOT_MORE_THAN_50_CH",
    },
  },
  language: {
    required: "LANGUAGE_REQUIRED",
  },

  // promptIntroAudio: {
  //   required:
  //     "mp3, .wav, .aac, .flac, .ogg, .wma, .aiff, .alac, .m4a, .amr (MAX 25MB) required",
  // },
  selectPredefined: {
    required: "PLEASE_SELECT_PREDEFINED_REMINDER_ID",
  },
  notes: {
    // required: "NOTES_REQUIRED",
    maxLength: {
      value: 300,
      message: "NOTES_TEXT_NOT_MORE_THAN_300_CH",
    },
  },
  selectPredefined: {
    required: "PLEASE_SELECT_PREDEFINED_REMINDER_ID",
  },
  reader: {
    // required: "READER_IS_REQUIRED",
    minLength: {
      value: 2,
      message: "READER_MUST_BE_AT_LEAST_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "READER_NOT_MORE_THAN_50_CHARACTERS",
    },
    // validate: {
    //   noWhitespace: (value) =>
    //     value.trim() !== "" || "READER_SHOULD_NOT_BE_WHITESPACES_ONLY",
    // },
    validate: {
      // Allow empty values or values with non-whitespace characters
      noWhitespace: (value) =>
        value.trim() === "" ||
        value.trim() !== "" ||
        "READER_SHOULD_NOT_BE_WHITESPACES_ONLY",
    },
  },
  independiCare: {
    // required: "REPRESENTATIVE_IS_REQUIRED",
    minLength: {
      value: 2,
      message: "REPRESENTATIVE_MUST_BE_AT_LEAST_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "REPRESENTATIVE_NOT_MORE_THAN_50_CHARACTERS",
    },
    // validate: {
    //   noWhitespace: (value) =>
    //     value.trim() !== "" || "INDEPENDICARE_SHOULD_NOT_BE_WHITESPACES_ONLY",
    // },
    validate: {
      // Allow empty values or values with non-whitespace characters
      noWhitespace: (value) =>
        value.trim() === "" ||
        value.trim() !== "" ||
        "INDEPENDICARE_SHOULD_NOT_BE_WHITESPACES_ONLY",
    },
  },
  siteTitle: {
    required: "SITE_TITLE_IS_REQUIRED",
    minLength: {
      value: 2,
      message: "SITE_TITLE_MUST_BE_AT_LEAST_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "SITE_TITLE_SHOULD_NOT_MORE_THAN_50_CHARACTERS",
    },
    validate: {
      noWhitespace: (value) =>
        value.trim() !== "" || "SITE_TITLE_SHOULD_NOT_BE_WHITESPACES_ONLY",
    },
  },
  messageTitle: {
    required: "TITLE_REQUIRED",

    minLength: {
      value: 2,
      message: "NAME_MAY_HAVE_MINIMUM_OF_2_CHARACTERS",
      // validate: (value) =>
      //   value.trim() !== "" || "Username cannot be empty or just spaces",
    },
    maxLength: {
      value: 100,
      message: "NAME_MAY_HAVE_MAXIMUM_OF_100_CHARACTERS",
    },
  },

  locationType: {
    required: "PLS_SELECT_LOCATION",
  },

  locationName: {
    required: "LOCATION_REQUIRED",
    minLength: {
      value: 2,
      message: "MIN_2_CHAR_REQUIRED",
    },
    maxLength: {
      value: 50,
      message: "MAX_50_CHAR_ALLOWED",
    },
  },
  eventTypes: {
    required: "EVENT_TYPE_REQUIRED",
    minLength: {
      value: 2,
      message: "MIN_2_CHAR_REQUIRED",
    },
    maxLength: {
      value: 50,
      message: "MAX_50_CHAR_ALLOWED",
    },
  },
  dailyevent: {
    required: "DAILY_EVENT_REQUIRED",
    minLength: {
      value: 2,
      message: "MIN_2_CHAR_REQUIRED",
    },
    maxLength: {
      value: 50,
      message: "MAX_50_CHAR_ALLOWED",
    },
  },
  timeMessage: {
    required: "TIME_MESSAGE_REQUIRED",
    minLength: {
      value: 2,
      message: "MIN_2_CHAR_REQUIRED",
    },
    maxLength: {
      value: 50,
      message: "MAX_50_CHAR_ALLOWED",
    },
  },
  locationMessage: {
    required: "LOCATION_MESSAGE_REQUIRED",
    minLength: {
      value: 2,
      message: "MIN_2_CHAR_REQUIRED",
      // validate: (value) =>
      //   value.trim() !== "" || "Username cannot be empty or just spaces",
    },
    maxLength: {
      value: 50,
      message: "MAX_50_CHAR_ALLOWED",
    },
  },
};
