export const timeZoneCountryMapping = {
  "Asia/Calcutta": {
    country: "in",
    code: "91",
  },
  Atlantic: {
    country: "us",
    code: "1",
  },
  Eastern: {
    country: "us",
    code: "1",
  },
  Central: {
    country: "us",
    code: "1",
  },
  Mountain: {
    country: "us",
    code: "1",
  },
  Pacific: {
    country: "us",
    code: "1",
  },
  Alaska: {
    country: "us",
    code: "1",
  },
  "Hawaii–Aleutian": {
    country: "us",
    code: "1",
  },
  "American Samoa": {
    country: "us",
    code: "1",
  },
  Chamorro: {
    country: "us",
    code: "1",
  },
};

export const personInfo = [
  // {
  //   siteId: 1002,
  //   personName: "Mark Spactor",
  //   PromptIntroTT: "Brother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1003,
  //   personName: "Shan Marsh",
  //   PromptIntroTT: "Father ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1004,
  //   personName: "S tanlee",
  //   PromptIntroTT: "Mother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1005,
  //   personName: "Donald Blake",
  //   PromptIntroTT: "Brother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1006,
  //   personName: "Steave Strange",
  //   PromptIntroTT: "Mother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1007,
  //   personName: "Adam Worlock",
  //   PromptIntroTT: "Father ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1008,
  //   personName: "Chrish Path",
  //   PromptIntroTT: "Brother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1007,
  //   personName: "Same Wilson",
  //   PromptIntroTT: "Mother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1008,
  //   personName: "Jonathan Major",
  //   PromptIntroTT: "Mother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1007,
  //   personName: "Vicktor Timly",
  //   PromptIntroTT: "Father ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
  // {
  //   siteId: 1007,
  //   personName: "Vicktor Doom",
  //   PromptIntroTT: "Brother ",
  //   PromptIntroWave: "Intro Mother.wave",
  //   CaregiverID: "Android TTS",
  //   languageToUse: "English",
  //   ttsCommandString: "Command String Lorem Ipsum ",
  //   usePromptIntro: "Lorem Ipsum is simply",
  //   alertWithTtsPrompt: "Lorem Ipsum is simply",
  //   alertToneWave: "Intro Mother.wave",
  //   notes: "Command String Lorem Ipsum ",
  // },
];
