import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomInputFields } from "../CustomComponent/CustomInputFields/CustomInputField";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { CustomPasswordInputField } from "../CustomComponent/CustomInputFields/CustomInputField";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../CustomComponent/CustomButton/CustomButton";
import Toster from "../../Toster/Toster";
import { useDispatch, useSelector } from "react-redux";
import { userSignIn } from "../../redux/slices/userSlice";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Loader from "../LoaderComponent/LoaderComponent";

//
import styles from "./LoginPage.module.css";
import logo from "../../Assests/Images/logo.png";
import stakeholderUncheckImg from "../../Assests/Images/stakeholderUncheckImg.svg";
import caregiverCheckImg from "../../Assests/Images/caregiverCheckImg.svg";
import stakeholderCheckImg from "../../Assests/Images/stakeholderCheckImg.svg";
import caregiverUncheckImg from "../../Assests/Images/caregiverUncheckImg.svg";
import { setUserType } from "../../redux/slices/userSlice";
import { CONSTANT } from "../../Utils/Constant";

function UserSelectionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });

  const usertype = useSelector((state) => state.user.userType);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("user_type", usertype);
    const userData = {
      formData: formData,
      usertype: usertype,
    };
    setIsLoading(true);
    dispatch(userSignIn(userData)).then((data) => {
      setIsLoading(false);
      const response = data.payload;
      const ResponseCode = STATUS_MSG[response?.data.code];
      if (response?.code == STATUS_CODE.SUCCESS) {
        if (usertype === "stakeholder") {
          navigate("/site");
        } else if (usertype === "caregiver") {
          navigate("/stakeholder");
        } else if (
          response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response?.data?.message, "error");
        }
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const initialUserType = localStorage.getItem("usertype") || "stakeholder";

  const [selectedOption, setSelectedOption] = useState(initialUserType);

  useEffect(() => {
    localStorage.setItem("usertype", selectedOption);
  }, [selectedOption]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    dispatch(setUserType(value));
  };

  return (
    <>
      {isLoading && <Loader />}
      <h3 className="headingContent">{t("WELCOME_BACK")}</h3>
      <p className="paragraphText">{`${t("LOGIN_TO")} ${usertype}`}</p>

      {/* ******* */}
      <div className="radio-with-Icon">
        <p className="radioOption-Item">
          <input
            type="radio"
            name="userType"
            id="BannerType1"
            value="stakeholder"
            checked={selectedOption === CONSTANT.STAKEHOLDER_KEY}
            onChange={handleChange}
            // aria-invalid="false"
          />
          <label htmlFor="BannerType1">
            <div>
              <img
                className={styles.bottomSpace}
                src={
                  selectedOption === CONSTANT.STAKEHOLDER_KEY
                    ? stakeholderCheckImg
                    : stakeholderUncheckImg
                }
                alt="First slide"
              />
            </div>
            <div>
              <h4>{t("STACKHOLDER")}</h4>
            </div>
          </label>
        </p>

        <p className="radioOption-Item">
          <input
            type="radio"
            name="userType"
            id="BannerType2"
            value="caregiver"
            checked={selectedOption === CONSTANT.CAREGIVER_KEY}
            onChange={handleChange}
            // aria-invalid="false"
          />
          <label htmlFor="BannerType2">
            <div>
              <img
                className={styles.bottomSpace}
                src={
                  selectedOption === CONSTANT.CAREGIVER_KEY
                    ? caregiverCheckImg
                    : caregiverUncheckImg
                }
                alt="Second slide"
              />
            </div>
            <div>
              <h4>{t("CAREGIVER")}</h4>
            </div>
          </label>
        </p>
      </div>
      {/* ******* */}
      <div className="registerFormsField">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CustomInputFields
            styleName="signUpTabInput"
            type="text"
            PlaceHolder={t("ENTER_EMAIL_ADDRESS")}
            RegisterName="email"
            register={register}
            formState={formState}
            label={t("EMAIL_ADDRESS")}
            setValue={setValue}
            // onChange={onChangeInputField}
          />
          <CustomPasswordInputField
            styleName="signUpTabInput"
            type="Password"
            PlaceHolder={t("PASSWORD")}
            RegisterName="password"
            register={register}
            formState={formState}
            label={t("PASSWORD")}
            setValue={setValue}
          />
          <p className="forgetPasswordText">
            <Link className="customLink" to={"/forget-password"}>
              {t("FORGET_PASSWORD")}?
            </Link>
          </p>
          <div className="buttonContent">
            <Row className="rowButtonContent">
              <Col lg={7} className="textCol">
                <p className="paragraphLink">
                  {t("DONT_HAVE_AN_ACCOUNT")}{" "}
                  <Link to={"/signup"} className="customLinkSignUp">
                    {t("SIGNUP")}
                  </Link>
                </p>
              </Col>
              <Col lg={5} className="d-flex justify-content-end setButton">
                <CustomButton
                  type="submit"
                  value={t("LOGIN")}
                  className="buttonProperty"
                />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
}

export default UserSelectionPage;
