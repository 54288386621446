import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "bootstrap/dist/css/bootstrap.min.css";
import icon_clock from "../../Assests/Images/icon_clock.png";

const DutyHoursComp = ({ onDutyHoursChange, initialDutyHours }) => {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Initialize state with either initialDutyHours or default values
  const [dutyHours, setDutyHours] = useState(
    initialDutyHours
      ? daysOfWeek.map((day) => {
          const prefilledDay = initialDutyHours.find(
            (item) => item.days.toLowerCase() === day.toLowerCase()
          );
          return prefilledDay
            ? {
                day,
                active: prefilledDay.is_available === "1",
                startTime: dayjs(
                  `1970-01-01T${prefilledDay.time_slots[0].start_time}`
                ),
                endTime: dayjs(
                  `1970-01-01T${prefilledDay.time_slots[0].end_time}`
                ),
              }
            : {
                day,
                active: false,
                startTime: null,
                endTime: null,
              };
        })
      : daysOfWeek.map((day) => ({
          day,
          active: false,
          startTime: null,
          endTime: null,
        }))
  );

  // Format and send the selected data back to the parent component
  useEffect(() => {
    const formattedData = dutyHours
      .filter(
        ({ active, startTime, endTime }) => active && startTime && endTime
      )
      .map(({ day, active, startTime, endTime }) => ({
        days: day.toLowerCase(),
        is_available: active ? "1" : "0",
        time_slots: [
          {
            // Format as HH:mm:ss
            start_time: startTime.format("HH:mm:ss"),
            end_time: endTime.format("HH:mm:ss"),
          },
        ],
      }));
    onDutyHoursChange(formattedData);
  }, [dutyHours, onDutyHoursChange]);

  // Handle toggling the active state for each day
  const handleToggle = (index) => {
    setDutyHours((prevState) => {
      const newState = [...prevState];
      if (newState[index].active) {
        newState[index].startTime = null;
        newState[index].endTime = null;
      }
      newState[index].active = !newState[index].active;
      return newState;
    });
  };

  // Handle time changes for start or end time
  const handleTimeChange = (index, timeType, time) => {
    setDutyHours((prevState) => {
      const newState = [...prevState];
      newState[index][timeType] = time;
      return newState;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="container-fluid">
        <h3 className="duty-hours-header">Duty Hours</h3>
        {dutyHours.map((day, index) => (
          <Form
            key={index}
            className={`day-block ${day.active ? "active" : "inactive"} mb-3`}
          >
            <Row>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-between"
              >
                <div className="day-name">{day.day}</div>
                <Form.Check
                  type="switch"
                  id={`custom-switch-${index}`}
                  className="toggle-switch"
                  checked={day.active}
                  onChange={() => handleToggle(index)}
                />
              </Col>
              {day.active && (
                <Col
                  xs={12}
                  className="d-flex justify-content-between align-items-center mt-2"
                >
                  <div className="time-selector">
                    <TimePicker
                      label="Start Time"
                      value={day.startTime}
                      onChange={(time) =>
                        handleTimeChange(index, "startTime", time)
                      }
                      renderInput={(params) => (
                        <input
                          {...params}
                          className="form-control time-picker"
                        />
                      )}
                      ampm
                      minutesStep={1}
                      maxTime={day.endTime || null} // Prevent start time after end time
                    />
                  </div>
                  <div
                    style={{ marginLeft: 10, marginRight: 10 }}
                    className="totext"
                  >
                    To
                  </div>
                  <div className="time-selector">
                    <TimePicker
                      label="End Time"
                      value={day.endTime}
                      onChange={(time) =>
                        handleTimeChange(index, "endTime", time)
                      }
                      renderInput={(params) => (
                        <input
                          {...params}
                          className="form-control time-picker"
                        />
                      )}
                      ampm
                      minutesStep={1}
                      minTime={day.startTime || null} // Prevent end time before start time
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Form>
        ))}
      </div>
    </LocalizationProvider>
  );
};

export default DutyHoursComp;
