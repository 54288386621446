import React from 'react';
import Container  from 'react-bootstrap/Container';
import Logo from '../../Logo/Logo'
import style from './CustomFormBackground.module.css'
import { useTranslation } from "react-i18next";
import { Outlet } from 'react-router-dom';

function UserSelectionPage() {
    const { t } = useTranslation();
return (
    <div className={style.fullBackground}>
        <Container className={style.mAuto}>
            <div className={style.alignmentProperty}>
                <div className={style.logoSection}>
                    <Logo/>
                </div>
            </div>
            <div className={`${style.formFieldSection} ${style.mainSection}`}>
                <div className={style.inputFieldSection}>
                    <Outlet/>
                </div>
            </div>
        </Container>
    </div>
    );
}

export default UserSelectionPage;



